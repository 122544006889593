import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useHistory } from "react-router";
import DCCLogo from "../../../assets/DCC-Logo.svg";
import logo from "../../../assets/images/shareholder-app.svg";
import checkCircle from "../../../assets/images/circle-check.png";
import { AiOutlineLeft } from "react-icons/ai";
import { numberWithCommas } from "../../../utilities/utilityFunctions";
import {
  addDirectorElectionVoting,
  addSpecialElectionVoting,
  generateOTPByMobileAndEmail,
  getCandidateDatabyElectionID,
  getSharesByCNIC,
  verifyOTP,
} from "../../../store/services/evoting.service";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import "react-toastify/dist/ReactToastify.css";
import "./evotingStyle.scss";
import { Logout } from "./logout";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import ReactQuill from "react-quill";
import { saveAs } from "file-saver";

// import { useState } from 'react';
export const ResolutionVoting = () => {
  // const getEvotingData = JSON.parse(localStorage.getItem("evotingData"));
  const getEvotingData = JSON.parse(sessionStorage.getItem("evotingData"));
  // const getUserData = JSON.parse(localStorage.getItem("user_data"));
  const getUserData = JSON.parse(sessionStorage.getItem("user_data"));
  // const getTempEmail = JSON.parse(localStorage.getItem("email"));
  const getTempEmail = JSON.parse(sessionStorage.getItem("email"));
  // const getShareHolder = JSON.parse(
  //   localStorage.getItem("shareholdervotecast")
  // );
  const getShareHolder = JSON.parse(
    sessionStorage.getItem("shareholdervotecast")
  );
  let parseData;
  try {
    parseData = JSON.parse(getEvotingData?.data?.attachments || "");
  } catch (error) {
    // Handle the error here
    console.error("Error parsing JSON data:", error);
  }
  const [file, setFile] = useState(parseData === "_" ? "" : parseData || "");
  const [fileName, setFileName] = useState(
    parseData?.name === "_" ? "" : parseData?.name || ""
  );
  const [file1, setFile1] = useState(
    parseData?.content === "_" ? "" : parseData?.content || ""
  );
  const [file64, setFile64] = useState(
    parseData?.content === "_" ? "" : parseData?.content || ""
  );
  const [fileData, setFileData] = useState(
    parseData?.content === "_" ? "" : parseData?.content || ""
  );
  const [termCondition, setTermCondition] = useState(false);
  const [reviewVoting, setReviewVoting] = useState(false);
  const [confirmvoting, setConfirmVoting] = useState(false);
  const [totalShares, setTotalShares] = useState(0);
  const [shareHolderData, setShareHolderData] = useState({});
  const [otp, setOTP] = useState("");
  const [voteArray, setVoteArray] = useState([]);
  const [loading, setLoading] = useState(false);
  const [confirmVote, setConfirmVote] = useState(false);

  let totalvotes = 0;
  let preViewTotalVotes = 0;
  let vote = 0;
  const [inputValues, setInputValues] = useState([]);
  //const voteArray=[]

  const [candidateData, setCandidateData] = useState([]);
  const [generateOTPFlag, setGenerateOTPFlag] = useState(true);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    const getShares = async () => {
      const response = await getSharesByCNIC(
        getTempEmail,
        getEvotingData?.data?.company_code,
        getUserData?.cnic
      );
      if (response.data.status == 200) {
        let filter = response.data.data.filter(
          (data) => data.folio_number == getShareHolder
        );

        setShareHolderData(filter[0]);
        setTotalShares(
          parseFloat(filter[0].electronic_shares) +
            parseFloat(filter[0].physical_shares)
        );
      }
    };

    getShares();
  }, []);

  function removeLinks(htmlString) {
    const parser = new DOMParser();
    const html = parser.parseFromString(htmlString, "text/html");

    // Select all <a> elements
    const links = html.querySelectorAll("a");

    // Loop over all <a> elements and replace them with their text content
    for (let link of links) {
      link.outerHTML = link.textContent;
    }

    return html.body.innerHTML;
  }

  const handleInputChange = (id, event) => {
    const newInputValues = [...inputValues];
    const index = newInputValues?.findIndex((item) => item?.id === id);
    newInputValues[index].value = event.target.value;
    setInputValues(newInputValues);
  };
  window.addEventListener("popstate", () => {
    confirmvoting && history.replace(`${process.env.PUBLIC_URL}/evoting/home`);
  });
  const [voteCastedFlag, setVoteCastedFlag] = useState(false);
  const handlevoting = () => {
    let temp = getEvotingData?.data.agendas
      ? JSON.parse(getEvotingData?.data.agendas)
      : [];
    let flag = true;
    for (let i = 0; i < temp.length; i++) {
      if (typeof voteArray[i] === "undefined") {
        flag = false;
      }
    }
    if (flag) {
      setVoteCastedFlag(true);
      if (confirmVote) {
        if (
          new Date().getTime() >=
          new Date(getEvotingData?.data?.agenda_to).getTime()
        ) {
          toast.error("Voting Time Expired");
          setTimeout(() => {
            history.replace(`${process.env.PUBLIC_URL}/evoting/home`);
          }, 3000);
        } else {
          handlePreViewVoting();
        }
      } else {
        setConfirmVote(true);
      }
    } else {
      toast.error("Please cast all vote ");
    }
  };
  const handleHome = () => {
    window.location.replace(`${process.env.PUBLIC_URL}/evoting/home`);
  };
  const handlePreViewVoting = async () => {
    setLoading(true);
    let vote_response;
    let agendas = JSON.parse(getEvotingData?.data.agendas);
    let vote = getEvotingData?.data.voting_id
      ? getEvotingData?.data.previous_vote
      : [];

    for (let i = 0; i < agendas.length; i++) {
      if (voteArray[i] && voteArray[i] != "") {
        vote.push({
          item_no: agendas[i].item_no,
          s_no: agendas[i].s_no,
          votes_casted: totalShares.toString(),
          voting_percentage: "100%",
          votes_favour: voteArray[i] == "Approved" ? "1" : "0",
          votes_against: voteArray[i] == "Disapproved" ? "1" : "0",
          votes_accepted: "",
          votes_rejected: "",
          remarks: "",
          vote_cast_date_time: new Date().toString().substring(0, 24),
          agenda_title: agendas[i].agenda_title,
          agenda_item: agendas[i].agenda_item,
        });
      }
    }

    if (vote.length > 0) {
      vote_response = await addSpecialElectionVoting(
        getTempEmail,
        getEvotingData?.data.voting_id ? getEvotingData?.data.voting_id : "",
        getEvotingData?.data.item_id,
        "Electronic",
        "Mobile App",
        shareHolderData.folio_number,
        shareHolderData.folio_number,
        "",
        totalShares.toString(),
        totalShares.toString(),
        vote,
        totalShares.toString(),
        getEvotingData?.id,
        getEvotingData?.company_code
      );
      if (vote_response && vote_response.data.status == 200) {
        toast.success(vote_response.data.message);
        setReviewVoting(false);
        setConfirmVoting(true);
        setLoading(false);
        setConfirmVote(false);
      } else {
        toast.error(vote_response.data.message);
        setGenerateOTPFlag(true);
        setConfirmVote(false);
        setLoading(false);
      }
    } else {
      setReviewVoting(false);
      setConfirmVoting(true);
      setLoading(false);
    }
  };
  const generateOTP = async () => {
    const response = await generateOTPByMobileAndEmail(
      getUserData?.email,
      getUserData?.mobile_no
    );
    if (response.data.status == 200) {
      toast.success(response.data.message);
      setReviewVoting(true);
      setGenerateOTPFlag(false);
    } else {
      toast.error(response.data.message);
    }
  };

  const [viewModalPicture, setViewModalPicture] = useState(false);
  const [imagePath, setImagePath] = useState("");
  const [viewFlag, setViewFlag] = useState(false);
  const [viewAgendaData, setViewAgendaData] = useState({});
  const history = useHistory();
  const handleViewImage = (pathVal) => {
    setImagePath(pathVal);
    setViewModalPicture(true);
  };

  return (
    <>
      <ToastContainer />
      {confirmvoting ? (
        <div className="evotingfontfamily" style={{ padding: "20px" }}>
          <MainWrapper className="row mt-4">
            <PaddingWrapper className="col-sm-12 col-md-12 col-lg-12 responsivepage">
              <div
                className="card pb-5"
                style={{ border: "1px solid #70ace7" }}
              >
                <Wrapper
                  className="card-header "
                  style={{ borderBottom: "none" }}
                >
                   <div
                    className="d-flex"
                    style={{ justifyContent: "space-between" }}
                  >
                    <AiOutlineLeft
                      className="mt-3"
                      size={30}
                      style={{ color: "#677ecc", cursor: "pointer" }}
                      onClick={() => {
                        handleHome()
                      }}
                    />
                    <Logout />
                  </div>
                  <div className="responsivepaddingpage">
                    <div className="text-center mt-1">
                      <img
                        src={
                          getEvotingData.logo
                            ? getEvotingData.logo
                            : require("../../../assets/images/defaultImg.png")
                        }
                        alt=""
                        maxWidth={"220px"}
                        height={"130px"}
                        style={{
                          maxWidth: "220px",
                          maxHeight: "130px",
                          width: "auto",
                          height: "auto",
                        }}
                      />
                    </div>
                    <div
                      className="mt-3"
                      style={{
                        color: "#447bc9",
                        fontSize: "23px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      Special Resolution
                    </div>
                    <p
                      style={{
                        fontSize: "16px",
                        display: "flex",
                        justifyContent: "center",
                        color: "rgb(152, 161, 181)",
                        textAlign: "center",
                      }}
                      class="card-text"
                    >
                      {moment(getEvotingData?.data?.agenda_from).format(
                        "DD-MMM-YYYY h:mmA"
                      )}{" "}
                      <br /> TO
                      <br />
                      {moment(getEvotingData?.data?.agenda_to).format(
                        "DD-MMM-YYYY h:mmA"
                      )}
                    </p>
                  </div>
                </Wrapper>

                <div
                  className="card-body mt-4"
                  style={{ justifyContent: "center", display: "flex" }}
                >
                  <div>
                    <div className="text-center mt-1">
                      <img
                        src={checkCircle}
                        alt=""
                        width="130px"
                        height={"130px"}
                        style={{
                          maxWidth: "220px",
                          maxHeight: "130px",
                          width: "auto",
                          height: "auto",
                        }}
                      />
                    </div>
                    <div
                      style={{
                        justifyContent: "center",
                        display: "flex",
                        color: "#00b050",
                        fontSize: "20px",
                      }}
                    >
                      {" "}
                      Vote Cast Successful
                    </div>

                    <div
                      className="mt-4"
                      style={{
                        justifyContent: "center",
                        display: "flex",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      {" "}
                      Your votes have been casted
                    </div>
                    <div
                      style={{
                        justifyContent: "center",
                        display: "flex",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      {" "}
                      successfully.
                    </div>
                    {/* Results will */}
                    {/* <div
                      style={{
                        justifyContent: "center",
                        display: "flex",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      appear in the app at the
                    </div>
                    <div
                      style={{
                        justifyContent: "center",
                        display: "flex",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      specified time.
                    </div> */}

                    <button
                      className="btn mt-5"
                      type="button"
                      style={{
                        width: "100%",
                        color: "#ffff",
                        background: "#5B9BD5",
                      }}
                      onClick={() => {
                        handleHome();
                      }}
                    >
                      <span> HOME</span>
                    </button>
                  </div>
                </div>
              </div>
            </PaddingWrapper>
          </MainWrapper>
        </div>
      ) : reviewVoting ? (
        <div className="evotingfontfamily" style={{ padding: "20px" }}>
          <ToastContainer />
          <MainWrapper className="row mt-4">
            <PaddingWrapper className="col-sm-12 col-md-12 col-lg-12 responsivepage ">
              <div
                className="card pb-5 "
                style={{ border: "1px solid #70ace7" }}
              >
                <Wrapper
                  className="card-header "
                  style={{ borderBottom: "none" }}
                >
                  <div
                    className="d-flex"
                    style={{ justifyContent: "space-between" }}
                  >
                    <AiOutlineLeft
                      className="mt-3"
                      size={30}
                      style={{ color: "#677ecc", cursor: "pointer" }}
                      onClick={() => {
                        setReviewVoting(false);
                        setVoteCastedFlag(false);
                      }}
                    />
                    <Logout />
                  </div>
                  <div className="responsivepaddingpage">
                    <div className="text-center mt-1">
                      <img
                        src={
                          getEvotingData.logo
                            ? getEvotingData.logo
                            : require("../../../assets/images/defaultImg.png")
                        }
                        alt=""
                        maxWidth={"220px"}
                        height={"130px"}
                        style={{
                          maxWidth: "220px",
                          maxHeight: "130px",
                          width: "auto",
                          height: "auto",
                        }}
                      />
                    </div>
                    <div
                      className="mt-3"
                      style={{
                        color: "#447bc9",
                        fontSize: "23px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      Special Resolution
                    </div>
                    <p
                      style={{
                        fontSize: "16px",
                        display: "flex",
                        justifyContent: "center",
                        color: "rgb(152, 161, 181)",
                        textAlign: "center",
                      }}
                      class="card-text"
                    >
                      {moment(getEvotingData?.data?.agenda_from).format(
                        "DD-MMM-YYYY h:mmA"
                      )}{" "}
                      <br /> TO
                      <br />
                      {moment(getEvotingData?.data?.agenda_to).format(
                        "DD-MMM-YYYY h:mmA"
                      )}
                    </p>
                  </div>
                </Wrapper>

                <div
                  className="card-body"
                  style={{ justifyContent: "center", display: "flex" }}
                >
                  <div>
                    {inputValues?.map((item, key) => {
                      const startDate = new Date(item?.startdate);
                      const endDate = new Date(item?.endDate);
                      preViewTotalVotes =
                        preViewTotalVotes + (Number(item?.value) || 0);
                      return (
                        <>
                          {" "}
                          <div key={key} class="row no-gutters">
                            <div class="col-md-12">
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  fontSize: "18px",
                                  color: "#5B9BD5",
                                }}
                              >
                                {" "}
                                Candidate {key + 1}{" "}
                                <span style={{ paddingLeft: "5px" }}>
                                  {" "}
                                  {item?.Name?.toUpperCase()}
                                </span>
                              </div>
                              <div
                                style={{
                                  fontSize: "20px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                {" "}
                                {numberWithCommas(item?.value) || 0}{" "}
                                <span
                                  style={{
                                    fontSize: "14px",
                                    paddingLeft: "8px",
                                    color: "rgb(152, 161, 181)",
                                  }}
                                >
                                  votes
                                </span>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                    <div>
                      <label
                        className="col-form-label pt-0 justify-content-center d-flex mt-5"
                        style={{
                          fontSize: "16px",
                          color: "rgb(152, 161, 181)",
                        }}
                      >
                        Enter Confirmation OTP
                      </label>
                      <input
                        style={{
                          minWidth: "260px",
                          maxWidth: "260px",
                          textAlign: "center",
                        }}
                        type="text"
                        className="form-control mt-1"
                        onChange={(e) => setOTP(e.target.value)}
                      />

                      <button
                        className="btn mt-3"
                        type="button"
                        disabled={loading}
                        style={{
                          width: "100%",
                          color: "#ffff",
                          background: "#5B9BD5",
                        }}
                        onClick={() => {
                          if (
                            new Date().getTime() >=
                            new Date(getEvotingData?.election_to).getTime()
                          ) {
                            toast.error("Voting Time Expired");
                            setTimeout(() => {
                              history.replace(
                                `${process.env.PUBLIC_URL}/evoting/home`
                              );
                            }, 3000);
                          } else {
                            handlePreViewVoting();
                          }
                        }}
                      >
                        <span>{loading ? "Casting Votes..." : "CONFIRM"}</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </PaddingWrapper>
          </MainWrapper>
        </div>
      ) : (
        <div className="evotingfontfamily" style={{ padding: "20px" }}>
          <Modal isOpen={viewFlag} show={viewFlag.toString()} size="xs">
            <ModalHeader
              toggle={() => {
                setViewFlag(false);
              }}
            >
              Agenda Details
            </ModalHeader>
            <ModalBody>
              <p
                style={{
                  color: "rgb(152, 161, 181)",
                  textAlign: "center",
                }}
              >
                Agenda Title
              </p>
              <div
                style={{
                  border: "1px solid rgb(152, 161, 181)",
                  margin: "auto",
                  margin: "3px",
                  textAlign: "center",
                  padding: "10px",
                }}
              >
                {/* <p style={{
                   
                   color: "#000000",
                   textAlign: "center",
                 }}> */}
                {viewAgendaData.agenda_title}
                {/* </p> */}
              </div>
              <p
                style={{
                  color: "rgb(152, 161, 181)",
                  textAlign: "center",
                  marginTop: "15px",
                }}
              >
                Agenda Item
              </p>
              <div
                style={{
                  border: "1px solid rgb(152, 161, 181)",
                  margin: "3px",
                }}
              >
                <div>
                  <ReactQuill
                    disabled={true}
                    placeholder="Enter Agenda Details"
                    value={removeLinks(
                      viewAgendaData.agenda_item &&
                        viewAgendaData.agenda_item
                          .replaceAll("<u>", "")
                          .replaceAll("</u>", "")
                    )}
                    readOnly={true}
                    modules={{
                      toolbar: false,
                    }}
                  />
                </div>
                {file !== "" &&
                  file !== "_" &&
                  file !== undefined &&
                  file !== null &&
                  file !== "null" && (
                    <div>
                      <p
                        style={{
                          color: "rgb(152, 161, 181)",
                          textAlign: "center",
                        }}
                      >
                        Attachment
                      </p>
                      {/* <div style={{
                    border: "1px solid rgb(152, 161, 181)",
                    margin: "auto",
                    margin: "3px",
                  }}> */}
                      <p
                        style={{
                          color: "#000000",
                          textAlign: "center",
                        }}
                      >
                        {file !== "" &&
                          file !== "_" &&
                          file !== undefined &&
                          file !== null &&
                          file !== "null" && (
                            <div
                              className="viewImage"
                              style={{ paddingTop: "20px" }}
                            >
                              {file?.type?.includes("image") ||
                              (file1 && file1?.includes("image")) ? (
                                <div
                                  style={{
                                    position: "relative",
                                    display: "inline-block",
                                  }}
                                >
                                  <img
                                    width="200"
                                    src={
                                      !file64?.content
                                        ? file64
                                        : file64?.content
                                    }
                                    onClick={() =>
                                      handleViewImage(
                                        !file64?.content
                                          ? file64
                                          : file64?.content
                                      )
                                    }
                                    alt="Attachment"
                                  />
                                </div>
                              ) : file?.type?.includes("application/pdf") ||
                                (file1 &&
                                  file1?.includes("application/pdf")) ? (
                                <div
                                  style={{ position: "relative", top: "-20px" }}
                                >
                                  <div
                                    style={{
                                      position: "relative",
                                      display: "inline-block",
                                      fontSize: "24px",
                                      color: "#E9ECEF",
                                    }}
                                  >
                                    <a
                                      href={`data:application/pdf;base64,${fileData}`}
                                      download={fileName}
                                    >
                                      {/* <FontAwesomeIcon icon={faDownload} className="icon" size="2x" /> */}
                                      <i
                                        className="fa fa-download"
                                        style={{ color: "#808080" }}
                                        size="4x"
                                        onClick={(e) => {
                                          const cleanedFileData =
                                            fileData.replace(
                                              /^data:application\/pdf;base64,/,
                                              ""
                                            );
                                          const byteCharacters =
                                            atob(cleanedFileData);
                                          const byteNumbers = new Array(
                                            byteCharacters.length
                                          );
                                          for (
                                            let i = 0;
                                            i < byteCharacters.length;
                                            i++
                                          ) {
                                            byteNumbers[i] =
                                              byteCharacters.charCodeAt(i);
                                          }
                                          const byteArray = new Uint8Array(
                                            byteNumbers
                                          );
                                          const blob = new Blob([byteArray], {
                                            type: "application/pdf",
                                          });
                                          saveAs(blob, fileName);
                                        }}
                                      ></i>
                                    </a>
                                  </div>
                                  {`  `}{" "}
                                  <p
                                    style={{
                                      color: "rgb(152, 161, 181)",
                                      textAlign: "center",
                                    }}
                                  >
                                    Download Resolution Details
                                  </p>
                                </div>
                              ) : (
                                <p>Unsupported file type</p>
                              )}
                            </div>
                          )}
                      </p>
                      {/* </div> */}
                    </div>
                  )}
              </div>
            </ModalBody>
          </Modal>
          <MainWrapper className="row mt-4">
            <PaddingWrapper className="col-sm-12 col-md-12 col-lg-12 responsivepage">
              <div
                className="card pb-5 "
                style={{ border: "1px solid #70ace7" }}
              >
                <Wrapper
                  className="card-header "
                  style={{ borderBottom: "none" }}
                >
                  <div
                    className="d-flex"
                    style={{ justifyContent: "space-between" }}
                  >
                    <AiOutlineLeft
                      className="mt-3"
                      size={30}
                      style={{ color: "#677ecc", cursor: "pointer" }}
                      onClick={() => {
                        setVoteCastedFlag(false);
                        if (confirmVote) {
                          setConfirmVote(false);
                        } else {
                          history.push(`${process.env.PUBLIC_URL}/home`);
                        }
                      }}
                    />
                    <Logout />
                  </div>

                  <div className="responsivepaddingpage">
                    <div className="text-center mt-1">
                      <img
                        src={
                          getEvotingData.logo
                            ? getEvotingData.logo
                            : require("../../../assets/images/defaultImg.png")
                        }
                        alt=""
                        maxWidth={"220px"}
                        height={"130px"}
                        style={{
                          maxWidth: "220px",
                          maxHeight: "130px",
                          width: "auto",
                          height: "auto",
                        }}
                      />
                    </div>
                    <div
                      className="mt-3"
                      style={{
                        color: "#447bc9",
                        fontSize: "23px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      Special Resolution
                    </div>
                    <p
                      style={{
                        fontSize: "16px",
                        display: "flex",
                        justifyContent: "center",
                        color: "rgb(152, 161, 181)",
                        textAlign: "center",
                        maxWidth: "300px",
                        margin: "auto",
                      }}
                      class="card-text"
                    >
                      {moment(getEvotingData?.data?.agenda_from).format(
                        "DD-MMM-YYYY h:mmA"
                      )}{" "}
                      <br /> TO
                      <br />
                      {moment(getEvotingData?.data?.agenda_to).format(
                        "DD-MMM-YYYY h:mmA"
                      )}
                    </p>
                    <div
                      class="mt-3"
                      style={{
                        justifyContent: "center",
                        display: "flex",
                        fontSize: "20px",
                      }}
                    >
                      {confirmVote
                        ? loading
                          ? " "
                          : "Review Your Vote "
                        : "Enter Your Vote"}
                    </div>
                  </div>
                </Wrapper>
                <div
                  className="card-body"
                  style={{ justifyContent: "center", display: "flex" }}
                >
                  <div style={{ maxWidth: "300px", textAlign: "center" }}>
                    <div>
                      {getEvotingData?.data.agendas &&
                        JSON.parse(getEvotingData?.data.agendas).map(
                          (item, index) => {
                            return (
                              <div
                                style={{
                                  textAlign: "center",
                                  marginBottom: "5px",
                                }}
                              >
                                <div style={{ color: "rgb(152, 161, 181)" }}>
                                  Special Resolution {index + 1}
                                </div>
                                <div
                                  style={{
                                    border: "1px solid rgb(152, 161, 181)",
                                    margin: "auto",
                                    margin: "3px",
                                  }}
                                >
                                  <div
                                    style={{ color: "#000000", padding: "5px" }}
                                  >
                                    {item.agenda_title}
                                  </div>
                                  <div
                                    style={{
                                      color: "#5b9bd5",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      setViewAgendaData(item);
                                      setViewFlag(true);
                                    }}
                                  >
                                    View
                                  </div>
                                </div>
                                {(!confirmVote ||
                                  (confirmVote &&
                                    voteArray[index] == "Approved")) && (
                                  <div
                                    className="d-flex"
                                    style={{
                                      justifyContent: "center",
                                      textAlign: "center",
                                    }}
                                  >
                                    <input
                                      type="checkbox"
                                      disabled={confirmVote}
                                      checked={
                                        voteArray[index] == "Approved"
                                          ? true
                                          : false
                                      }
                                      onClick={(e) => {
                                        if (voteArray[index] == "Approved") {
                                          voteArray[index] = undefined;
                                          setVoteArray([...voteArray]);
                                        } else {
                                          let index =
                                            e.target.value.split("-")[0];
                                          index = parseInt(index);

                                          voteArray[index] =
                                            e.target.value.split("-")[1];
                                          setVoteArray([...voteArray]);
                                        }
                                        const checkboxes =
                                          document.getElementsByName(
                                            `group${index}`
                                          );
                                        checkboxes.forEach((checkbox) => {
                                          checkbox.addEventListener(
                                            "click",
                                            () => {
                                              checkboxes.forEach((cb) => {
                                                if (cb !== checkbox) {
                                                  cb.checked = false;
                                                }
                                              });
                                            }
                                          );
                                        });
                                      }}
                                      name={`group${index}`}
                                      value={`${index}-Approved`}
                                    />

                                    {voteArray[index] == "Approved" &&
                                    voteCastedFlag ? (
                                      <label
                                        style={{
                                          width: "70px",
                                          alignSelf: "center",
                                          height: "12px",
                                          textAlign: "center",
                                          color: "green",
                                        }}
                                      >
                                        Favour
                                      </label>
                                    ) : (
                                      <label
                                        style={{
                                          width: "70px",
                                          alignSelf: "center",
                                          height: "12px",
                                          textAlign: "center",
                                        }}
                                      >
                                        Favour
                                      </label>
                                    )}
                                  </div>
                                )}

                                {(!confirmVote ||
                                  (confirmVote &&
                                    voteArray[index] == "Disapproved")) && (
                                  <div
                                    className="d-flex"
                                    style={{
                                      justifyContent: "center",
                                      textAlign: "center",
                                    }}
                                  >
                                    <input
                                      disabled={confirmVote}
                                      type="checkbox"
                                      name={`group${index}`}
                                      checked={
                                        voteArray[index] == "Disapproved"
                                          ? true
                                          : false
                                      }
                                      onClick={(e) => {
                                        if (voteArray[index] == "Disapproved") {
                                          voteArray[index] = undefined;
                                          setVoteArray([...voteArray]);
                                        } else {
                                          let index =
                                            e.target.value.split("-")[0];
                                          index = parseInt(index);
                                          voteArray[index] =
                                            e.target.value.split("-")[1];

                                          setVoteArray([...voteArray]);
                                        }

                                        const checkboxes =
                                          document.getElementsByName(
                                            `group${index}`
                                          );
                                        checkboxes.forEach((checkbox) => {
                                          checkbox.addEventListener(
                                            "click",
                                            () => {
                                              checkboxes.forEach((cb) => {
                                                if (cb !== checkbox) {
                                                  cb.checked = false;
                                                }
                                              });
                                            }
                                          );
                                        });
                                      }}
                                      value={`${index}-Disapproved`}
                                    />
                                    {voteArray[index] == "Disapproved" &&
                                    voteCastedFlag ? (
                                      <label
                                        style={{
                                          width: "71px",
                                          alignSelf: "center",
                                          height: "12px",
                                          textAlign: "center",
                                          color: "red",
                                        }}
                                      >
                                        Against
                                      </label>
                                    ) : (
                                      <label
                                        style={{
                                          width: "71px",
                                          alignSelf: "center",
                                          height: "12px",
                                          textAlign: "center",
                                        }}
                                      >
                                        Against
                                      </label>
                                    )}
                                  </div>
                                )}
                              </div>
                            );
                          }
                        )}

                      <button
                        className="btn mt-3"
                        type="button"
                        disabled={loading}
                        style={{
                          width: "100%",
                          color: "#ffff",
                          background: "#5B9BD5",
                        }}
                        onClick={() => {
                          handlevoting();
                          window.scrollTo({
                            top: 0,
                            behavior: "smooth",
                          });
                        }}
                      >
                        <span>
                          {" "}
                          {confirmVote
                            ? loading
                              ? "Casting Votes..."
                              : "CONFIRM"
                            : "Cast Votes"}
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
                {/* <div className=" mt-4" style={{ justifyContent: 'center', display: 'flex' }}>
              <div>
                <div className='mt-4' style={{ color: '#447bc9', fontSize: '23px' }}>
                  {getEvotingData?.title || ''}
                </div>
                <p style={{ fontSize: '16px' }} class="card-text">{getEvotingData?.startdate} to {getEvotingData?.endDate}</p>


                <div style={{ marginTop: '110px' }}>
                  <div style={{ display: 'flex', gap: '12px', justifyContent: 'center' }}>
                    <input type="checkbox" id="checkbox" name="checkbox" style={{ transform: 'scale(2)' }} onChange={(e) => {
                     setTermCondition(e?.target?.checked)
                    }} />
                    <div style={{ fontSize: '20px' }}> I agree to the</div>
                  </div>

                  <div style={{ display: 'flex', justifyContent: 'center', color: '#677ecc', fontSize: '20px' }}>Terms and Conditions</div>
                  <div style={{ display: 'flex', justifyContent: 'center', fontSize: '20px' }}>of the eVoting</div>
                </div>

                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <button className='btn'
                    type="button"
                    style={{ color: '#ffff', background: '#5b9bd5', minWidth :'140px' }}
                    disabled={!termCondition}
                    >
                      Enter
                  </button>
                </div>
              </div>
              <div>
              </div>
            </div> */}
              </div>
            </PaddingWrapper>
          </MainWrapper>
        </div>
      )}
    </>
  );
};

const MainWrapper = styled.div`
  @media (min-width: 300px) {
    .responsivepage {
    }
  }
  @media (min-width: 500px) {
    .responsivepage {
      display: flex;
      justify-content: center;
    }
  }
`;

const PaddingWrapper = styled.div`
  @media (min-width: 300px) {
    .responsivepaddingpage {
      ${"" /* width: 100%; */}
    }
  }
  @media (min-width: 500px) {
    .responsivepaddingpage {
      ${
        "" /* display: flex;
      justify-content: center; */
      }
      padding: 0px 100px;
      min-width: 550px;
      max-width: 550px;
      ${
        "" /* width: 50%;
      margin:auto; */
      }
    }
  }
`;

const Wrapper = styled.div`
  @media (min-width: 300px) {
    .responsive {
      width: 80%;
    }
  }
  @media (min-width: 500px) {
    .responsive {
      width: 53%;
    }
  }
  @media (min-width: 660px) {
    .responsive {
      width: 45%;
    }
  }
  @media (min-width: 840px) {
    .responsive {
      width: 35%;
    }
  }
  @media (min-width: 1132px) {
    .responsive {
      width: 26%;
    }
  }

  @media (min-width: 1132px) {
    .responsive {
      width: 22%;
    }
  }

  @media (min-width: 1300px) {
    .responsive {
      width: 20%;
    }
  }
  @media (min-width: 1470px) {
    .responsive {
      width: 15%;
    }
  }
`;
