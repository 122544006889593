import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import { firebase_app, auth0 } from "./data/config";
import {
  configureFakeBackend,
  authHeader,
  handleResponse,
} from "./services/fack.backend";
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
  useLocation,
} from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import AuthGuard from "./auth/AuthGuard";
// * Import custom components for redux *
import { Provider } from "react-redux";
import store from "./store";
import App from "./components/app";
import { Auth0Provider } from "@auth0/auth0-react";

// Import custom Components
import Default from "./components/dashboard/defaultCompo/default";

import Signin from "./auth/signin";
import ForgetPassword from "./auth/forgetPassword";
//config data
import configDB from "./data/customizer/config";

import Callback from "./auth/callback";
import { EvotingHome } from "./components/shareRegistrar/evotingComponents/Home";
// import share registrar
import AddCompany from "./components/shareRegistrar/company/addCompany";
import EditCompany from "./components/shareRegistrar/company/editCompany";
import ViewCompany from "./components/shareRegistrar/company/viewCompany";
import AddBankAccount from "./components/shareRegistrar/accounts/addBankAccount";
import AddInvestors from "./components/shareRegistrar/investors/addInvestors";
import ViewInvestor from "./components/shareRegistrar/investors/viewInvestor";
import EditInvestor from "./components/shareRegistrar/investors/editInvestor";
import InvestorRequests from "./components/shareRegistrar/investors/investorRequest/investorRequests";
import AddShareholders from "./components/shareRegistrar/shareholders/addShareholders";
import ViewShareholder from "./components/shareRegistrar/shareholders/viewShareholder";
import EditShareholder from "./components/shareRegistrar/shareholders/editShareholder";
import BankAccountListing from "./components/shareRegistrar/accounts/bankAccountListing";
import AddTransaction from "./components/shareRegistrar/transaction/addTransaction";
import ViewTransaction from "./components/shareRegistrar/transaction/viewTransaction";
import EditTransaction from "./components/shareRegistrar/transaction/editTransaction";
import ComplianceCheck from "./components/shareRegistrar/compliance/addCompliance";
import ViewCompliance from "./components/shareRegistrar/compliance/viewCompliance";
import EditCompliance from "./components/shareRegistrar/compliance/editCompliance";
import CompanyListing from "./components/shareRegistrar/company/companyListing";
import InvestorsListing from "./components/shareRegistrar/investors/investorsListing";
import InvestorRequestListing from "./components/shareRegistrar/investors/investorRequest/investorRequestListing";
import ShareholderListing from "./components/shareRegistrar/shareholders/shareholderListing";
import TransactionListing from "./components/shareRegistrar/transaction/transactionListing";
import TransactionTypeListing from "./components/shareRegistrar/transaction/transactionType/transactionTypeListing";
import AddTransactionType from "./components/shareRegistrar/transaction/transactionType/addTransactionType";
import EditTransactionType from "./components/shareRegistrar/transaction/transactionType/editTransactionType";
import ComplianceListing from "./components/shareRegistrar/compliance/complianceListing";
import ChecklistListing from "./components/shareRegistrar/checklist/processChecklist/checklistListing";
import AddChecklist from "./components/shareRegistrar/checklist/processChecklist/addChecklist";
import EditChecklist from "./components/shareRegistrar/checklist/processChecklist/editChecklist";
import ChecklistItemListing from "./components/shareRegistrar/checklist/checklistItem/checklistItemListing";
import AddChecklistItem from "./components/shareRegistrar/checklist/checklistItem/addChecklistItem";
import ViewChecklistItem from "./components/shareRegistrar/checklist/checklistItem/viewChecklistItem";
import EditChecklistItem from "./components/shareRegistrar/checklist/checklistItem/editChecklistItem";
import ShareListing from "./components/shareRegistrar/share/shareListing";
import AddShare from "./components/shareRegistrar/share/addShare";
import EditShare from "./components/shareRegistrar/share/editShare";
import AddAnnouncement from "./components/shareRegistrar/corporate/announcement/addAnnouncement";
import AnnouncementListing from "./components/shareRegistrar/corporate/announcement/announcementListing";
import ViewAnnouncement from "./components/shareRegistrar/corporate/announcement/viewAnnouncement";
import EditAnnouncement from "./components/shareRegistrar/corporate/announcement/editAnnouncement";
import AddEntitlement from "./components/shareRegistrar/corporate/entitlement/addEntitlement";
import ViewEntitlement from "./components/shareRegistrar/corporate/entitlement/viewEntitlement";
import EditEntitlement from "./components/shareRegistrar/corporate/entitlement/editEntitlement";
import EntitlementListing from "./components/shareRegistrar/corporate/entitlement/entitlementListing";
import ChargesListing from "./components/shareRegistrar/charges/chargesListing";
import AddCharges from "./components/shareRegistrar/charges/addCharges";
import EditCharges from "./components/shareRegistrar/charges/editCharges";
import DisbursementListing from "./components/shareRegistrar/dividend/disbursementListing";
import AddDisbursement from "./components/shareRegistrar/dividend/addDisbursement";
import EditInvestorRequests from "./components/shareRegistrar/investors/investorRequest/editInvestorRequest";
import ViewInvestorRequests from "./components/shareRegistrar/investors/investorRequest/viewInvestorRequest";
import EditDisbursement from "./components/shareRegistrar/dividend/editDisbursement";
import ShareUploader from "./components/shareRegistrar/shareholders/shareholding-uploader";
import ShareCertificateIssuanceListing from "./components/shareRegistrar/share-certificate/shareCertificateIssuanceListing";
import UserListing from "./components/shareRegistrar/user-management/userListing";
import RoleListing from "./components/shareRegistrar/role-management/roleListing";

import TransactionRequestListing from "./components/shareRegistrar/processing/transactionRequestListing";
import SplitShares from "./components/shareRegistrar/share/splitShareCertificate/SplitShares";
import ConsolidateShares from "./components/shareRegistrar/share/consolidateShares/consolidateShares";

import PhysicalToElectronic from "./components/shareRegistrar/share/physicalToElectronic/physicalToElectronic";
import ElectronicToPhysical from "./components/shareRegistrar/share/electronicToPhysical/electronicToPhysical";
import { shareHoldingBulkUpload } from "./store/services/shareholder.service";
import ShareHoldingBulkUpload from "./components/shareRegistrar/shareholders/physicalHoldingUploader";
import CertificateUploader from "./components/shareRegistrar/share-certificate/shareCertificateUploader";
import DuplicateShareCertificate from "./components/shareRegistrar/share/duplicateSharesCertificate/duplicateShareCertificate";
import ShareholdingHistory from "./components/reporting/shareholdingHistory";
import RightAllotmentReport from "./components/reporting/rightAllotmentReport";
import InvestorRequestReport from "./components/reporting/investorsRequestReport";
import SignUp from "./auth/signup";
import passwordConfirmation from "./auth/passwordConfirmation";
import { ElectionVoting } from "./components/shareRegistrar/evotingComponents/electionvoting";
import { CanidateVoting } from "./components/shareRegistrar/evotingComponents/canidatevotingPage";
import { SpecialResolution } from "./components/shareRegistrar/evotingComponents/SpecialResolution";
import { SpecialVoting } from "./components/shareRegistrar/evotingComponents/SpecialVoting";
import { Electionresult } from "./components/shareRegistrar/evotingComponents/Electionresult";
import { SpecialVotinResult } from "./components/shareRegistrar/evotingComponents/SpecialVotingResult";
import { SpecialResolutionElectionVoting } from "./components/shareRegistrar/evotingComponents/specialResolutionVoting";
import { ResolutionVoting } from "./components/shareRegistrar/evotingComponents/resolutionVoting";
import { ViewVotesCasted } from "./components/shareRegistrar/evotingComponents/ViewVotesCasted";
import { SpecialResolutionResultPage } from "./components/shareRegistrar/evotingComponents/SpecialResolutionResultPage";
import { EvotingInstruction } from "./components/shareRegistrar/evotingComponents/evotingInstruction";
// import EditAnnouncement from "./components/shareRegistrar/corporate/announcement/editAnnouncement";

// setup fake backend
// configureFakeBackend();

const Root = () => {
  const abortController = new AbortController();
  const [currentUser, setCurrentUser] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  // const jwt_token = localStorage.getItem("token");
  const jwt_token = sessionStorage.getItem("token");

  useEffect(() => {
    const color = sessionStorage.getItem("color");
    // const layout =
    //   localStorage.getItem("layout_version") ||
    //   configDB.data.color.layout_version;
    const layout = "light" || configDB.data.color.layout_version;

    // firebase_app.auth().onAuthStateChanged(setCurrentUser);
    // setAuthenticated(JSON.parse(localStorage.getItem("authenticated")));
    setAuthenticated(JSON.parse(sessionStorage.getItem("authenticated")));
    document.body.classList.add(layout);
    console.ignoredYellowBox = ["Warning: Each", "Warning: Failed"];
    console.disableYellowBox = true;
    document
      .getElementById("color")
      .setAttribute(
        "href",
        `${process.env.PUBLIC_URL}/assets/css/${color}.css`
      );

    return function cleanup() {
      abortController.abort();
    };

    // eslint-disable-next-line
  }, []);

  return (
    <div className="App">
      <Provider store={store}>
        <BrowserRouter basename={`/`}>
          <Switch>
            <AuthGuard
              exact
              path={`${process.env.PUBLIC_URL}/shareregistrar`}
              render={() => {
                return <Redirect to={`${process.env.PUBLIC_URL}/login`} />;
              }}
            />

            <Route
              exact
              path={`${process.env.PUBLIC_URL}/login`}
              component={Signin}
            />
            <AuthGuard
              exact
              path={`${process.env.PUBLIC_URL}/signup`}
              component={SignUp}
            />

            <AuthGuard
              exact
              path={`${process.env.PUBLIC_URL}/password-confirmation`}
              component={passwordConfirmation}
            />
            <AuthGuard
              exact
              path={`${process.env.PUBLIC_URL}/forget-password`}
              component={ForgetPassword}
            />
            <AuthGuard
              exact
              path={`${process.env.PUBLIC_URL}/evoting/home`}
              component={EvotingHome}
            />
            <AuthGuard
              exact
              path={`${process.env.PUBLIC_URL}/view-votes-casted`}
              component={ViewVotesCasted}
            />
            <AuthGuard
              exact
              path={`${process.env.PUBLIC_URL}/resolution_election-voting`}
              component={SpecialResolutionElectionVoting}
            />
            <AuthGuard
              exact
              path={`${process.env.PUBLIC_URL}/view-special-resolution-vote`}
              component={SpecialResolutionResultPage}
            />
            <AuthGuard
              exact
              path={`${process.env.PUBLIC_URL}/election-voting`}
              component={ElectionVoting}
            />
            <AuthGuard
              exact
              path={`${process.env.PUBLIC_URL}/voting`}
              component={CanidateVoting}
            />
            <AuthGuard
              exact
              path={`${process.env.PUBLIC_URL}/resolution-voting`}
              component={ResolutionVoting}
            />
            <AuthGuard
              exact
              path={`${process.env.PUBLIC_URL}/special-resolution`}
              component={SpecialResolution}
            />
            <AuthGuard
              exact
              path={`${process.env.PUBLIC_URL}/specialvoting`}
              component={SpecialVoting}
            />
            <AuthGuard
              exact
              path={`${process.env.PUBLIC_URL}/election-voting-result`}
              component={Electionresult}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/instructions`}
              component={EvotingInstruction}
            />
            <AuthGuard
              exact
              path={`${process.env.PUBLIC_URL}/special-voting-result`}
              component={SpecialVotinResult}
            />

            <Route
              path="*"
              render={() =>
                sessionStorage.getItem("token") ? (
                  <Redirect to={`${process.env.PUBLIC_URL}/evoting/home`} />
                ) : (
                  <Redirect to={`${process.env.PUBLIC_URL}/login`} />
                )
              }
            />

            {/* <AuthGuard
                exact
                path={`${process.env.PUBLIC_URL}/evoting/home`}
                component={EvotingHome}
              /> */}
            {/* <AuthGuard
              path={`${process.env.PUBLIC_URL}/callback`}
              render={() => <Callback />}
            /> */}

            <App>
              {/* <AuthGuard
                exact
                path={`/`}
                render={() => {
                  return (
                    <Redirect
                      to={`${process.env.PUBLIC_URL}/dashboard/default`}
                    />
                  );
                }}
              />
              <AuthGuard
                exact
                path={`${process.env.PUBLIC_URL}/dashboard/default`}
                component={Default}
              />





              <AuthGuard
                exact
                path={`${process.env.PUBLIC_URL}/add-company`}
                component={AddCompany}
              />
              <AuthGuard
                exact
                path={`${process.env.PUBLIC_URL}/view-company`}
                component={ViewCompany}
              />
              <AuthGuard
                exact
                path={`${process.env.PUBLIC_URL}/edit-company`}
                component={EditCompany}
              />
              <AuthGuard
                exact
                path={`${process.env.PUBLIC_URL}/company-listing`}
                component={CompanyListing}
              />
              <AuthGuard
                exact
                path={`${process.env.PUBLIC_URL}/bank-account-listing`}
                component={BankAccountListing}
              />
              <AuthGuard
                exact
                path={`${process.env.PUBLIC_URL}/add-bank-account`}
                component={AddBankAccount}
              />
              <AuthGuard
                exact
                path={`${process.env.PUBLIC_URL}/investors-listing`}
                component={InvestorsListing}
              />
              <AuthGuard
                exact
                path={`${process.env.PUBLIC_URL}/add-investors`}
                component={AddInvestors}
              />
              <AuthGuard
                exact
                path={`${process.env.PUBLIC_URL}/view-investor`}
                component={ViewInvestor}
              />
              <AuthGuard
                exact
                path={`${process.env.PUBLIC_URL}/edit-investor`}
                component={EditInvestor}
              />
              <AuthGuard
                exact
                path={`${process.env.PUBLIC_URL}/investor-request-listing`}
                component={InvestorRequestListing}
              /> */}
              {/* Transaction Requests*/}
              {/* <AuthGuard
                exact
                path={`${process.env.PUBLIC_URL}/transaction-requests`}
                component={TransactionRequestListing}
              />
              <AuthGuard
                exact
                path={`${process.env.PUBLIC_URL}/edit-investor-request`}
                component={EditInvestorRequests}
              />
              <AuthGuard
                exact
                path={`${process.env.PUBLIC_URL}/view-investor-request`}
                component={ViewInvestorRequests}
              />
              <AuthGuard
                exact
                path={`${process.env.PUBLIC_URL}/investor-requests`}
                component={InvestorRequests}
              />
              <AuthGuard
                exact
                path={`${process.env.PUBLIC_URL}/shareholder-listing`}
                component={ShareholderListing}
              /> */}
              {/* Reporting  Start*/}
              {/* Shareholding History*/}
              {/* <AuthGuard
                exact
                path={`${process.env.PUBLIC_URL}/shareholding-history`}
                component={ShareholdingHistory}
              /> */}
              {/* Right Allotment History*/}
              {/* <AuthGuard
                exact
                path={`${process.env.PUBLIC_URL}/right-allotment-report`}
                component={RightAllotmentReport}
              /> */}
              {/* Investors Request Report*/}
              {/* <AuthGuard
                exact
                path={`${process.env.PUBLIC_URL}/investors-request-report`}
                component={InvestorRequestReport}
              /> */}
              {/* Reporting End*/}
              {/* <AuthGuard
                exact
                path={`${process.env.PUBLIC_URL}/share-certificate-listing`}
                component={ShareCertificateIssuanceListing}
              />
              <AuthGuard
                exact
                path={`${process.env.PUBLIC_URL}/shareholding-uploader`}
                component={ShareUploader}
              />
              <AuthGuard
                exact
                path={`${process.env.PUBLIC_URL}/physical-file-uploader`}
                component={ShareHoldingBulkUpload}
              />
              <AuthGuard
                exact
                path={`${process.env.PUBLIC_URL}/certificate-file-uploader`}
                component={CertificateUploader}
              />
              <AuthGuard
                exact
                path={`${process.env.PUBLIC_URL}/add-shareholder`}
                component={AddShareholders}
              />
              <AuthGuard
                exact
                path={`${process.env.PUBLIC_URL}/view-shareholder`}
                component={ViewShareholder}
              />
              <AuthGuard
                exact
                path={`${process.env.PUBLIC_URL}/edit-shareholder`}
                component={EditShareholder}
              />
              <AuthGuard
                exact
                path={`${process.env.PUBLIC_URL}/transaction-listing`}
                component={TransactionListing}
              />
              <AuthGuard
                exact
                path={`${process.env.PUBLIC_URL}/transaction-type-listing`}
                component={TransactionTypeListing}
              />
              <AuthGuard
                exact
                path={`${process.env.PUBLIC_URL}/add-transaction-type`}
                component={AddTransactionType}
              />

              <AuthGuard
                exact
                path={`${process.env.PUBLIC_URL}/edit-transaction-type`}
                component={EditTransactionType}
              />
              <AuthGuard
                exact
                path={`${process.env.PUBLIC_URL}/add-transaction`}
                component={AddTransaction}
              />
              <AuthGuard
                exact
                path={`${process.env.PUBLIC_URL}/view-transaction`}
                component={ViewTransaction}
              />
              <AuthGuard
                exact
                path={`${process.env.PUBLIC_URL}/edit-transaction`}
                component={EditTransaction}
              />
              <AuthGuard
                exact
                path={`${process.env.PUBLIC_URL}/compliance-listing`}
                component={ComplianceListing}
              />
              <AuthGuard
                exact
                path={`${process.env.PUBLIC_URL}/add-compliance`}
                component={ComplianceCheck}
              />
              <AuthGuard
                exact
                path={`${process.env.PUBLIC_URL}/view-compliance`}
                component={ViewCompliance}
              />
              <AuthGuard
                exact
                path={`${process.env.PUBLIC_URL}/edit-compliance`}
                component={EditCompliance}
              />
              <AuthGuard
                exact
                path={`${process.env.PUBLIC_URL}/process-checklist-listing`}
                component={ChecklistListing}
              />
              <AuthGuard
                exact
                path={`${process.env.PUBLIC_URL}/add-process-checklist`}
                component={AddChecklist}
              />
              <AuthGuard
                exact
                path={`${process.env.PUBLIC_URL}/edit-process-checklist`}
                component={EditChecklist}
              />
              <AuthGuard
                exact
                path={`${process.env.PUBLIC_URL}/checklist-item-listing`}
                component={ChecklistItemListing}
              />
              <AuthGuard
                exact
                path={`${process.env.PUBLIC_URL}/users-listing`}
                component={UserListing}
              />
              <AuthGuard
                exact
                path={`${process.env.PUBLIC_URL}/roles-listing`}
                component={RoleListing}
              />
              <AuthGuard
                exact
                path={`${process.env.PUBLIC_URL}/add-checklist-item`}
                component={AddChecklistItem}
              />
              <AuthGuard
                exact
                path={`${process.env.PUBLIC_URL}/view-checklist-item`}
                component={ViewChecklistItem}
              />
              <AuthGuard
                exact
                path={`${process.env.PUBLIC_URL}/edit-checklist-item`}
                component={EditChecklistItem}
              />
              <AuthGuard
                exact
                path={`${process.env.PUBLIC_URL}/share-listing`}
                component={ShareListing}
              />
              <AuthGuard
                exact
                path={`${process.env.PUBLIC_URL}/add-share`}
                component={AddShare}
              />
              <AuthGuard
                exact
                path={`${process.env.PUBLIC_URL}/edit-share`}
                component={EditShare}
              />
              <AuthGuard
                exact
                path={`${process.env.PUBLIC_URL}/add-corporate-announcement`}
                component={AddAnnouncement}
              />
              <AuthGuard
                exact
                path={`${process.env.PUBLIC_URL}/view-corporate-announcement`}
                component={ViewAnnouncement}
              />
              <AuthGuard
                exact
                path={`${process.env.PUBLIC_URL}/edit-corporate-announcement`}
                component={EditAnnouncement}
              />
              <AuthGuard
                exact
                path={`${process.env.PUBLIC_URL}/corporate-announcement-listing`}
                component={AnnouncementListing}
              />
              <AuthGuard
                exact
                path={`${process.env.PUBLIC_URL}/corporate-entitlement-listing`}
                component={EntitlementListing}
              />
              <AuthGuard
                exact
                path={`${process.env.PUBLIC_URL}/add-corporate-entitlement`}
                component={AddEntitlement}
              />
              <AuthGuard
                exact
                path={`${process.env.PUBLIC_URL}/view-corporate-entitlement`}
                component={ViewEntitlement}
              />
              <AuthGuard
                exact
                path={`${process.env.PUBLIC_URL}/edit-corporate-entitlement`}
                component={EditEntitlement}
              />
              <AuthGuard
                exact
                path={`${process.env.PUBLIC_URL}/charges-listing`}
                component={ChargesListing}
              />
              <AuthGuard
                exact
                path={`${process.env.PUBLIC_URL}/add-charges`}
                component={AddCharges}
              />
              <AuthGuard
                exact
                path={`${process.env.PUBLIC_URL}/edit-charges`}
                component={EditCharges}
              />
              <AuthGuard
                exact
                path={`${process.env.PUBLIC_URL}/disbursement-listing`}
                component={DisbursementListing}
              />
              <AuthGuard
                exact
                path={`${process.env.PUBLIC_URL}/edit-disbursement`}
                component={EditDisbursement}
              />
              <AuthGuard
                exact
                path={`${process.env.PUBLIC_URL}/add-disbursement`}
                component={AddDisbursement}
              /> */}
            </App>
          </Switch>
        </BrowserRouter>
      </Provider>
    </div>
  );
};

ReactDOM.render(<Root />, document.getElementById("root"));

serviceWorker.unregister();
