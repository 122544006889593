import React, { useEffect, useState } from "react";
import styled from "styled-components";
import DCCLogo from "../../../assets/DCC-Logo.svg";
import logo from "../../../assets/images/shareholder-app.svg";
import Spinner from "../../common/spinner";

import { useHistory } from "react-router";
import { numberWithCommas } from "../../../utilities/utilityFunctions";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import "./evotingStyle.scss";
import {
  ByCNICForVote,
  ByCNICForVotingDetails,
  checkResolutionvote,
  getAllElectionOfDirector,
  getAllSpecialAgenda,
  getCompaniesforVoting,
  getCompaniesforVotingByCompanyCodes,
  getSharesByCNIC,
} from "../../../store/services/evoting.service";
import moment from "moment";
import { Logout } from "./logout";
import { ToastContainer, toast } from "react-toastify";

export const EvotingHome = () => {
  const isSmallScreen = window.matchMedia("(max-width: 500px)").matches;
  // const getTempEmail = JSON.parse(localStorage.getItem("email"));
  const getTempEmail = JSON.parse(sessionStorage.getItem("email"));
  // const getUserData = JSON.parse(localStorage.getItem("user_data"));
  const getUserData = JSON.parse(sessionStorage.getItem("user_data"));
  // const getUserCompany = JSON.parse(localStorage.getItem("user_company"));
  const getUserCompany = JSON.parse(sessionStorage.getItem("user_company"));

  // const getUser_last_shareHolder = localStorage.getItem("user_last_shareholder")
  //   ? JSON.parse(localStorage.getItem("user_last_shareholder"))
  //   : undefined;
  const [specialVotingDetail, setSpecialVotingDetail] = useState(false);
  const currentDate = new Date();
  const history = useHistory();
  const [electionofDirectors, setElectionofDirectors] = useState([]);
  const [specialAgenda, setSpecialAgenda] = useState([]);
  const [allCompanies, setAllCompanies] = useState([]);
  const [uniqueSpecialAgenda, setUniqueSpecialAgenda] = useState([]);
  const [loading, setLoading] = useState(true);
  const [votedDirectorElection, setVotedDirectorElection] = useState([]);
  const [votedResolutionElection, setVotedResolutionElection] = useState([]);
  const [directorVotingView, setDirectorVotingView] = useState([]);

  const [votedDirectorDetail, setVotedDirectorDetail] = useState({});
  const [resolutionVotingView, setResolutioVotingView] = useState([]);

  const [votedResolutionDetail, setVotedResolutionDetail] = useState({});

  const [showDirectorVotingDetail, setShowDirectorVotingDetail] =
    useState(false);

  let splitCategory = JSON.parse(directorVotingView?.vote_casting || "[]");
  let femaleConidate = splitCategory.filter(
    (item) => item.category === "female_candidate"
  );
  let independentConidate = splitCategory.filter(
    (item) => item.category === "independent_candidate"
  );
  let nonIndependentConidate = splitCategory.filter(
    (item) => item.category === "non_independent_candidate"
  );

  //start special voting View result
  // const getEvotingData = JSON.parse(localStorage.getItem("evotingData"));
  // const getEvotingData = JSON.parse(sessionStorage.getItem("evotingData"));
  const [termCondition, setTermCondition] = useState(false);
  const [viewFlag, setViewFlag] = useState(false);
  const [viewAgendaData, setViewAgendaData] = useState({});
  //end special voting View result
  let totalvotes = 400;
  let preViewTotalVotes = 0;
  useEffect(() => {
    setLoading(true);
    const getAllCompany = async () => {
      // const response = await getCompaniesforVoting(getTempEmail);
      const response = await getCompaniesforVotingByCompanyCodes(
        getTempEmail,
        getUserCompany.join(",")
      );
      // const res = await ByCNICForVote(getTempEmail, getUserData?.cnic);
      const res = await ByCNICForVotingDetails(
        getTempEmail,
        getUserData?.cnic,
        getUserCompany.join(",")
      );
      if (response.data.status == 200) {
        setAllCompanies(response.data.data);
      }
      if (res.data.status == 200) {
        //setElectionofDirectors(res.data.director_elections)
        let agendas = res.data.special_agendas.filter((item) => {
          return item.agendas && item.agendas != "";
        });

        setSpecialAgenda(agendas);
        setVotedDirectorElection(res.data.director_elections_votes);
        setVotedResolutionElection(
          res.data.special_agendas_votes.filter((item) => {
            return (
              item.vote != "" &&
              item.vote != "Disapproved" &&
              item.vote != "Approved"
            );
          })
        );
        let temp1 = [];
        let temp2 = [];
        res.data.director_elections.map((item) => {
          for (let i = 0; i < res.data.data.length; i++) {
            if (
              item.company_code == res.data.data[i].company_code &&
              parseFloat(
                item.number_of_candidates ? item.number_of_candidates : "0"
              ) >
                parseFloat(
                  item.number_of_directors ? item.number_of_directors : "0"
                ) &&
              moment
                .duration(moment().diff(moment(item.election_to)))
                .asDays() < 3
            ) {
              temp2.push({
                data: item,
                company_code: item.company_code,
                folio_number: res.data.data[i].folio_number,
                holding:
                  parseFloat(res.data.data[i].electronic_shares) +
                  parseFloat(res.data.data[i].physical_shares),
              });
            }
          }
        });
        setElectionofDirectors(temp2);
        agendas.map((item) => {
          for (let i = 0; i < res.data.data.length; i++) {
            if (
              item.company_code == res.data.data[i].company_code &&
              moment.duration(moment().diff(moment(item.agenda_to))).asDays() <
                3
            ) {
              let agendas = JSON.parse(item.agendas).filter((item) => {
                return item.status && item.status == "active";
              });
              item.agendas = agendas ? JSON.stringify(agendas) : "[]";
              temp1.push({
                id: item.meeting_id,
                company_code: item.company_code,
                folio_number: res.data.data[i].folio_number,
                data: item,
                holding:
                  parseFloat(res.data.data[i].electronic_shares) +
                  parseFloat(res.data.data[i].physical_shares),
              });
            }
          }
        });
        // let unique_heading = temp1.filter(
        //   (v, i, a) =>
        //     a.findIndex((v2) =>
        //       ["id", "company_code", "share_holder"].every(
        //         (k) => v2[k] === v[k]
        //       )
        //     ) === i
        // );
        // let allData = unique_heading.map((item, index) => {
        //   return {
        //     meeting_id: item.id,
        //     company_code: item.company_code,
        //     folio_number: item.share_holder,
        //     holding: item.holding,
        //     data1: agendas.filter((data, i) => {
        //       let agenda_id = res.data.special_agendas_votes.find(
        //         (x) =>
        //           x.agenda_id == data.item_id &&
        //           x.folio_number == item.share_holder &&
        //           x.vote != "Approved" &&
        //           x.vote != "Disapproved"
        //       );
        //       if (
        //         data.meeting_id == item.id &&
        //         data.company_code == item.company_code && !agenda_id
        //       ) {
        //         // let filtered_agendas = JSON.parse(data.agendas).filter(
        //         //   (item) => {
        //         //     return item.status && item.status == "active";
        //         //   }
        //         // );
        //         // if (agenda_id) {
        //         //   let vote = JSON.parse(agenda_id.vote);

        //         //   filtered_agendas = filtered_agendas.filter(
        //         //     (obj1) =>
        //         //       !vote.some((obj2) => obj2.item_no === obj1.item_no)
        //         //   );
        //         //   data.voting_id = agenda_id.voting_id;
        //         //   data.previous_vote = vote;
        //         // }
        //         //data.agendas = JSON.stringify(filtered_agendas);

        //           return {
        //             data,
        //             meeting_id: item,
        //           };

        //       }
        //     }),
        //   };
        // });
        setUniqueSpecialAgenda(temp1);
        if (temp1.length == 0 && temp2.length == 0) {
          toast.error("No Record Found");
        }
        setLoading(false);
      } else {
        toast.error("No Record Found");
        setLoading(false);
      }
    };

    //allelectionofdirector();
    //allspecialagenda();
    getAllCompany();
  }, []);
  window.addEventListener("popstate", () => {
    history.replace(`${process.env.PUBLIC_URL}/evoting/home`);
  });

  const dummyArrayforEvoting = [
    {
      logo: DCCLogo,
      title: "Election of Directors",
      startdate: "12-Mar-2023",
      endDate: "18-Mar-2023",
      Name: "touseeq",
    },
    {
      logo: DCCLogo,
      title: "Special Resolution",
      startdate: "20-Mar-2023",
      endDate: "23-Mar-2023",
      castVote: true,
      Name: "touseeq",
    },
    {
      logo: DCCLogo,
      title: "Election of Directors",
      startdate: "18-Mar-2023",
      endDate: "21-Mar-2023",
      specialvoting: true,
      Name: "touseeq",
    },
    {
      logo: DCCLogo,
      title: "Election of Directors",
      startdate: "18-Mar-2023",
      endDate: "21-Mar-2023",
      electionvoting: true,
      Name: "touseeq",
    },
    // {
    //     logo: DCCLogo,
    //     title: 'Election of Directors',
    //     startdate: '24-Mar-2023',
    //     endDate: '28-Mar-2023'
    // }
  ];
  const handleVoteCast = (data) => {
    const Jsondata = JSON.stringify(data);
    // localStorage.setItem("evotingData", Jsondata);
    sessionStorage.setItem("evotingData", Jsondata);
    history.replace(`${process.env.PUBLIC_URL}/voting`);
  };

  const handleSpecialResolutionVoteCast = (data) => {
    const Jsondata = JSON.stringify(data);
    // localStorage.setItem("evotingData", Jsondata);
    sessionStorage.setItem("evotingData", Jsondata);
    history.push(`${process.env.PUBLIC_URL}/resolution-voting`);
  };

  const handleViewResolution = (data) => {
    history.push(`${process.env.PUBLIC_URL}/special-resolution`);
  };
  const viewElectionResult = (data) => {
    history.push(`${process.env.PUBLIC_URL}/election-voting-result`, {
      data: dummyArrayforEvoting,
      result: data,
    });

    // history.push(`${process.env.PUBLIC_URL}/special-resolution`)
  };
  const viewSpecialVotingResult = (data) => {
    history.push(`${process.env.PUBLIC_URL}/special-voting-result`, {
      data: dummyArrayforEvoting,
      result: data,
    });
  };

  return (
    <>
      <ToastContainer />
      <Modal
        isOpen={specialVotingDetail}
        show={specialVotingDetail.toString()}
        size="sm"
      >
        <ModalHeader
          toggle={() => {
            setSpecialVotingDetail(false);
          }}
        >
          Agenda Voting Detail
        </ModalHeader>
        <ModalBody>
          <div className="evotingfontfamily">
            <div className="row mt-4 ">
              <ModelPaddingWrapper className="col-sm-12 col-md-12 col-lg-12 responsivepage">
                <div
                  className="card pb-5 "
                  style={{ border: "1px solid #70ace7" }}
                >
                  <Wrapper
                    className="card-header "
                    style={{ borderBottom: "none" }}
                  >
                    <div className="responsivemodelpaddingpage">
                      <div className="text-center mt-3">
                        <img
                          src={
                            votedResolutionDetail?.logo
                              ? votedResolutionDetail?.logo
                              : require("../../../assets/images/defaultImg.png")
                          }
                          alt=""
                          style={{
                            maxHeight: "120px",
                            maxWidth: "180px",
                            width: "auto",
                            height: "auto",
                          }}
                        />
                      </div>
                      {/* <div
                        className="mt-3"
                        style={{
                          justifyContent: "center",
                          display: "flex",
                          fontSize: "20px",
                        }}
                      >
                        eVoting on
                      </div> */}
                    </div>
                  </Wrapper>

                  <div
                    className=" mt-3"
                    style={{ justifyContent: "center", display: "flex" }}
                  >
                    <div>
                      <div
                        style={{
                          color: "#447bc9",
                          fontSize: "23px",
                          textAlign: "center",
                        }}
                      >
                        Special Resolution
                      </div>
                      <p
                        style={{
                          fontSize: "16px",
                          color: "rgb(152, 161, 181)",
                          textAlign: "center",
                        }}
                        className="card-text responsivemodelpaddingpage"
                      >
                        {votedResolutionDetail?.from_date}
                        <br /> TO <br /> {votedResolutionDetail?.to_date}
                      </p>
                      <div style={{ wordBreak: "break-word" }}>
                        {resolutionVotingView &&
                          resolutionVotingView.vote &&
                          JSON.parse(resolutionVotingView.vote).map(
                            (item, index) => {
                              return (
                                <div
                                  style={{
                                    textAlign: "center",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <div style={{ color: "rgb(152, 161, 181)" }}>
                                    Special Resolution {index + 1}
                                  </div>
                                  <div
                                    style={{
                                      color: "#000000",
                                      padding: "0px 10px",
                                    }}
                                  >
                                    {item.agenda_title}
                                  </div>
                                  <div
                                    className={
                                      item?.votes_favour == "1"
                                        ? "text-success"
                                        : "text-danger"
                                    }
                                  >
                                    {item?.votes_favour == "1"
                                      ? "favour".toUpperCase()
                                      : "against".toUpperCase()}
                                  </div>
                                </div>
                              );
                            }
                          )}
                      </div>
                    </div>

                    <div></div>
                  </div>
                </div>
              </ModelPaddingWrapper>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={showDirectorVotingDetail}
        show={showDirectorVotingDetail.toString()}
        size="sm"
      >
        <ModalHeader
          toggle={() => {
            setShowDirectorVotingDetail(false);
          }}
        >
          Director Voting Detail
        </ModalHeader>
        <ModalBody>
          <div className="evotingfontfamily">
            <MainWrapper className="row mt-4">
              <ModelPaddingWrapper className="col-sm-12 col-md-12 col-lg-12 responsivepage ">
                <div
                  className="card pb-5"
                  style={{ border: "1px solid #70ace7" }}
                >
                  <Wrapper
                    className="card-header "
                    style={{ borderBottom: "none" }}
                  >
                    <div className="responsivemodelpaddingpage">
                      <div className="text-center mt-1">
                        <img
                          src={
                            votedDirectorDetail?.logo
                              ? votedDirectorDetail?.logo
                              : require("../../../assets/images/defaultImg.png")
                          }
                          alt=""
                          style={{
                            maxHeight: "120px",
                            maxWidth: "180px",
                            width: "auto",
                            height: "auto",
                          }}
                        />
                      </div>
                      <div
                        className="mt-3"
                        style={{
                          color: "#447bc9",
                          fontSize: "23px",
                          display: "flex",
                          justifyContent: "center",
                          textAlign: "center",
                        }}
                      >
                        Election of Directors
                      </div>
                      <p
                        style={{
                          fontSize: "16px",
                          display: "flex",
                          justifyContent: "center",
                          color: "rgb(152, 161, 181)",
                          textAlign: "center",
                        }}
                        class="card-text"
                      >
                        {votedDirectorDetail?.from_date} <br /> TO <br />
                        {votedDirectorDetail?.to_date}
                      </p>

                      <div
                        className="mt-3"
                        style={{
                          justifyContent: "center",
                          display: "flex",
                          fontSize: "20px",
                        }}
                      >
                        Your Votes
                      </div>
                    </div>
                  </Wrapper>

                  {femaleConidate.length > 0 && (
                    <div
                      className="card-body pt-3 pb-3 mt-5 "
                      style={{
                        justifyContent: "center",
                        textAlign: "center",
                        display: "flex",
                        wordBreak: "break-word",
                        borderRadius: "10px",
                      }}
                    >
                      <div
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <h5>
                          <b>Female Candidates</b>
                        </h5>
                        {femaleConidate.map((item, key) => {
                          preViewTotalVotes =
                            preViewTotalVotes +
                            (Number(item?.votes_casted) || 0);
                          return (
                            <>
                              <div key={key} class="row no-gutters">
                                <div class="col-md-12 mt-3">
                                  <div
                                    style={{
                                      maxWidth: "260px",
                                      textAlign: "center",
                                      fontSize: "18px",
                                      color: "#5B9BD5",
                                      alignItems: "center",
                                    }}
                                  >
                                    {/* <table>
                                       <tr>
                                         <td className="text-nowrap" >{key + 1 + ". "}</td>
                                         
                                         <td >
                                           {item?.candidate_name?.toUpperCase()}
                                         </td>
                                       </tr>
                                     </table> */}

                                    {key + 1}
                                    {". "}
                                    <span style={{ paddingLeft: "5px" }}>
                                      {" "}
                                      {item?.candidate_name?.toUpperCase()}
                                    </span>
                                  </div>
                                  <div
                                    style={{
                                      fontSize: "20px",
                                      maxWidth: "260px",
                                      textAlign: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    {" "}
                                    {numberWithCommas(item?.votes_casted) ||
                                      0}{" "}
                                    <span
                                      style={{
                                        fontSize: "14px",
                                        paddingLeft: "8px",
                                        color: "rgb(152, 161, 181)",
                                      }}
                                    >
                                      votes
                                    </span>
                                  </div>
                                  <hr style={{ width: "60%" }} />
                                </div>
                              </div>
                            </>
                          );
                        })}

                        {/* <div className="b-t-primary b-b-primary">
                          <div
                            style={{
                              alignItems: "center",
                              fontSize: "18px",
                            }}
                          >
                            Total Votes:{" "}
                            <span style={{ color: "#5B9BD5" }}>
                              {" "}
                              {numberWithCommas(
                                directorVotingView.castable_votes_female
                              )}
                            </span>
                          </div>
                          <div
                            style={{ alignItems: "center", fontSize: "18px" }}
                          >
                            Votes Casted:{" "}
                            <span style={{ color: "#5B9BD5" }}>
                              {" "}
                              {numberWithCommas(
                                directorVotingView.castable_votes_female
                              )}
                            </span>
                          </div>
                          <div
                            style={{ alignItems: "center", fontSize: "18px" }}
                          >
                            Remaining Votes:{" "}
                            <span style={{ color: "#5B9BD5" }}>
                              {" "}
                              {numberWithCommas(0)}
                            </span>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  )}

                  {independentConidate.length > 0 && (
                    <div
                      className="card-body card-body pt-3 pb-3 mt-5 "
                      style={{
                        justifyContent: "center",
                        textAlign: "center",
                        display: "flex",
                        wordBreak: "break-word",
                        borderRadius: "10px",
                      }}
                    >
                      <div
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <h5>
                          <b>Independent Candidates</b>
                        </h5>
                        {independentConidate.map((item, key) => {
                          preViewTotalVotes =
                            preViewTotalVotes +
                            (Number(item?.votes_casted) || 0);
                          return (
                            <>
                              <div key={key} class="row no-gutters">
                                <div class="col-md-12 mt-3">
                                  <div
                                    style={{
                                      maxWidth: "260px",
                                      textAlign: "center",
                                      fontSize: "18px",
                                      color: "#5B9BD5",
                                      alignItems: "center",
                                    }}
                                  >
                                    {/* <table>
                                       <tr>
                                         <td className="text-nowrap" >{key + 1 + ". "}</td>
                                         
                                         <td >
                                           {item?.candidate_name?.toUpperCase()}
                                         </td>
                                       </tr>
                                     </table> */}

                                    {key + 1}
                                    {". "}
                                    <span style={{ paddingLeft: "5px" }}>
                                      {" "}
                                      {item?.candidate_name?.toUpperCase()}
                                    </span>
                                  </div>
                                  <div
                                    style={{
                                      fontSize: "20px",
                                      maxWidth: "260px",
                                      textAlign: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    {" "}
                                    {numberWithCommas(item?.votes_casted) ||
                                      0}{" "}
                                    <span
                                      style={{
                                        fontSize: "14px",
                                        paddingLeft: "8px",
                                        color: "rgb(152, 161, 181)",
                                      }}
                                    >
                                      votes
                                    </span>
                                  </div>
                                  <hr style={{ width: "60%" }} />
                                </div>
                              </div>
                            </>
                          );
                        })}

                        {/* <div>
                          <div
                            style={{
                              alignItems: "center",
                              fontSize: "18px",
                            }}
                          >
                            Total Votes:{" "}
                            <span style={{ color: "#5B9BD5" }}>
                              {" "}
                              {numberWithCommas(
                                directorVotingView.castable_votes_independent
                              )}
                            </span>
                          </div>
                          <div
                            style={{ alignItems: "center", fontSize: "18px" }}
                          >
                            Votes Casted:{" "}
                            <span style={{ color: "#5B9BD5" }}>
                              {" "}
                              {numberWithCommas(
                                directorVotingView.castable_votes_independent
                              )}
                            </span>
                          </div>
                          <div
                            style={{ alignItems: "center", fontSize: "18px" }}
                          >
                            Remaining Votes:{" "}
                            <span style={{ color: "#5B9BD5" }}>
                              {" "}
                              {numberWithCommas(0)}
                            </span>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  )}
                  {nonIndependentConidate.length > 0 && (
                    <div
                      className="card-body pt-3 pb-3 mt-5 "
                      style={{
                        justifyContent: "center",
                        textAlign: "center",
                        display: "flex",
                        wordBreak: "break-word",
                        borderRadius: "10px",
                      }}
                    >
                      <div
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <h5>
                          <b>Non Independent Candidates</b>
                        </h5>
                        {nonIndependentConidate.map((item, key) => {
                          preViewTotalVotes =
                            preViewTotalVotes +
                            (Number(item?.votes_casted) || 0);
                          return (
                            <>
                              <div key={key} class="row no-gutters">
                                <div class="col-md-12 mt-3">
                                  <div
                                    style={{
                                      maxWidth: "260px",
                                      textAlign: "center",
                                      fontSize: "18px",
                                      color: "#5B9BD5",
                                      alignItems: "center",
                                    }}
                                  >
                                    {/* <table>
                                        <tr>
                                          <td className="text-nowrap" >{key + 1 + ". "}</td>
                                          
                                          <td >
                                            {item?.candidate_name?.toUpperCase()}
                                          </td>
                                        </tr>
                                      </table> */}

                                    {key + 1}
                                    {". "}
                                    <span style={{ paddingLeft: "5px" }}>
                                      {" "}
                                      {item?.candidate_name?.toUpperCase()}
                                    </span>
                                  </div>
                                  <div
                                    style={{
                                      fontSize: "20px",
                                      maxWidth: "260px",
                                      textAlign: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    {" "}
                                    {numberWithCommas(item?.votes_casted) ||
                                      0}{" "}
                                    <span
                                      style={{
                                        fontSize: "14px",
                                        paddingLeft: "8px",
                                        color: "rgb(152, 161, 181)",
                                      }}
                                    >
                                      votes
                                    </span>
                                  </div>
                                  <hr style={{ width: "60%" }} />
                                </div>
                              </div>
                            </>
                          );
                        })}

                        {/* <div>
                          <div
                            style={{
                              alignItems: "center",
                              fontSize: "18px",
                            }}
                          >
                            Total Votes:{" "}
                            <span style={{ color: "#5B9BD5" }}>
                              {" "}
                              {numberWithCommas(
                                directorVotingView.castable_votes_non_independent
                              )}
                            </span>
                          </div>
                          <div
                            style={{ alignItems: "center", fontSize: "18px" }}
                          >
                            Votes Casted:{" "}
                            <span style={{ color: "#5B9BD5" }}>
                              {" "}
                              {numberWithCommas(
                                directorVotingView.castable_votes_non_independent
                              )}
                            </span>
                          </div>
                          <div
                            style={{ alignItems: "center", fontSize: "18px" }}
                          >
                            Remaining Votes:{" "}
                            <span style={{ color: "#5B9BD5" }}>
                              {" "}
                              {numberWithCommas(0)}
                            </span>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  )}

                  <div
                    className="card-body pt-3 pb-3 mt-5 "
                    style={{
                      justifyContent: "center",
                      textAlign: "center",
                      display: "flex",
                      wordBreak: "break-word",
                      borderRadius: "10px",
                    }}
                  >
                    <div>
                      <h5>
                        <b>Total Votes</b>
                      </h5>
                      <div
                        style={{
                          alignItems: "center",
                          fontSize: "18px",
                        }}
                      >
                        Total Votes:{" "}
                        <span style={{ color: "#5B9BD5" }}>
                          {" "}
                          {numberWithCommas(directorVotingView.castable_votes)}
                        </span>
                      </div>
                      <div style={{ alignItems: "center", fontSize: "18px" }}>
                        Votes Casted:{" "}
                        <span style={{ color: "#5B9BD5" }}>
                          {" "}
                          {numberWithCommas(directorVotingView.castable_votes)}
                        </span>
                      </div>
                      {/* <div style={{ alignItems: "center", fontSize: "18px" }}>
                        Remaining Votes:{" "}
                        <span style={{ color: "#5B9BD5" }}>
                          {" "}
                          {numberWithCommas(0)}
                        </span>
                      </div> */}
                    </div>
                  </div>
                  {/* <hr className="mt-3"  style={{
                        border: "1px solid rgb(152, 161, 181)",
                       width: '100%'
                       
                      }}
                    ></hr> */}
                </div>
              </ModelPaddingWrapper>
            </MainWrapper>
          </div>
        </ModalBody>
      </Modal>
      <div className="evotingfontfamily" style={{ padding: "20px" }}>
        <ToastContainer />
        <MainWrapper className="row mt-4">
          <ResponsiveWrapper className="col-sm-12 col-md-12 col-lg-12 responsivepage">
            <div
              className="card pb-5 responsivhomePage"
              style={{ border: "1px solid #70ace7" }}
            >
              <div
                className="row mr-3 mt-2"
                style={{ justifyContent: "right" }}
              >
                <Logout />
              </div>
              <Wrapper
                className="card-header "
                style={{ borderBottom: "none" }}
              >
                <div className="text-center">
                  <img src={logo} alt="" width="220" height={"130px"} />
                </div>
                <div
                  className="mt-3"
                  style={{
                    fontSize: "18px",
                    fontWeight: "bold",
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  Welcome to
                </div>
                <div
                  className="mt-1"
                  style={{
                    fontSize: "18px",
                    fontWeight: "bold",
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  D-Share Registry
                </div>
                <div
                  className="mt-3"
                  style={{
                    justifyContent: "center",
                    display: "flex",
                    color: "rgb(152, 161, 181)",
                  }}
                >
                  eVoting Listing
                </div>
                <hr className="responsive mt-4" />
              </Wrapper>

              <div
                className="card-body mt-4"
                style={{ justifyContent: "center", display: "flex" }}
              >
                {electionofDirectors &&
                  electionofDirectors.length > 0 &&
                  parseFloat(electionofDirectors[0].holding || "0") > 0 && (
                    <div>
                      {electionofDirectors &&
                        electionofDirectors.length > 0 &&
                        electionofDirectors?.map((item, key) => {
                          let voted = votedDirectorElection.findIndex(
                            (x) =>
                              x.election_id == item?.data?.election_id &&
                              x.folio_number == item.folio_number
                          );
                          const startDate = new Date(item?.data?.election_from);
                          const endDate = new Date(item?.data?.election_to);
                          const filter = allCompanies.find((code) => {
                            return code.code === item.company_code;
                          });

                          return (
                            <>
                              <div key={key}>
                                <div class="col-md-12">
                                  <div
                                    style={{
                                      alignItems: "center",
                                    }}
                                  >
                                    <div
                                      style={{
                                        alignItems: "center",
                                        justifyContent: "center",
                                        textAlign: "center",
                                      }}
                                    >
                                      <div
                                      //style={{ width: "auto", maxWidth: "120px",
                                      // }}
                                      >
                                        <img
                                          src={
                                            filter.logo
                                              ? filter.logo
                                              : require("../../../assets/images/defaultImg.png")
                                          }
                                          class="card-img"
                                          alt="Logo"
                                          style={{
                                            width: "auto",
                                            height: "auto",
                                            maxWidth: "220px",
                                            maxHeight: "120px",
                                          }}
                                        />
                                      </div>
                                      <br />
                                      <p
                                        style={{
                                          marginTop: "-20px",
                                          marginBottom: "10px",
                                        }}
                                        class="card-text"
                                      >
                                        {filter?.company_name}
                                      </p>
                                    </div>

                                    <div style={{ textAlign: "center" }}>
                                      <h5
                                        class="card-title"
                                        style={{ wordWrap: "break-word" }}
                                      >
                                        Election of Directors
                                      </h5>

                                      <p
                                        style={{
                                          marginTop: "-5px",
                                          paddingRight: "10px",
                                          maxWidth: "335px",
                                        }}
                                        class="card-text"
                                      >
                                        {moment(
                                          item?.data?.election_from
                                        ).format("DD-MMM-YYYY h:mmA")}
                                        <br />
                                        TO
                                        <br />
                                        {moment(item?.data?.election_to).format(
                                          "DD-MMM-YYYY h:mmA"
                                        )}
                                      </p>
                                      <div>
                                        <div
                                          className="d-flex"
                                          style={{
                                            marginTop: "-5px",
                                            justifyContent: "space-between",
                                            width: "100%",
                                          }}
                                        >
                                          <p
                                            style={{ marginTop: "-5px" }}
                                            className="card-text col-md-6 text-nowrap"
                                          >
                                            Shares:{" "}
                                            <b>
                                              {numberWithCommas(item?.holding)}
                                            </b>
                                          </p>
                                          <p
                                            style={{ marginTop: "-5px" }}
                                            className="card-text col-md-6 text-nowrap"
                                          >
                                            Folio #:{" "}
                                            <b>
                                              {item?.folio_number
                                                .split("-")
                                                .slice(1)
                                                .join("-")}
                                            </b>
                                          </p>
                                        </div>

                                        {new Date(currentDate).getTime() <
                                        new Date(startDate).getTime() ? (
                                          <button
                                            className="btn"
                                            type="button"
                                            style={{
                                              color: "#ffff",
                                              background: "#5B9BD5",
                                              marginTop: "-15px",
                                              minWidth: "170px",
                                              maxWidth: "170px",
                                            }}
                                            disabled={true}
                                          >
                                            <span> Yet Not Started</span>
                                          </button>
                                        ) : (new Date(currentDate).getTime() >=
                                            new Date(startDate).getTime() &&
                                            new Date(currentDate).getTime() <=
                                              new Date(endDate).getTime()) ||
                                          voted > -1 ? (
                                          <button
                                            className="btn"
                                            type="button"
                                            //disabled={voted > -1 ? true : false}
                                            style={{
                                              color: "#ffff",
                                              background: "#5B9BD5",
                                              marginTop: "-15px",
                                              width: "190px",
                                              textAlign: "center",
                                            }}
                                            onClick={() => {
                                              if (voted < 0) {
                                                if (
                                                  new Date().getTime() >=
                                                  new Date(endDate).getTime()
                                                ) {
                                                  toast.error(
                                                    "Voting Time Expired"
                                                  );
                                                  setTimeout(() => {
                                                    window.location.reload();
                                                  }, 3000);
                                                } else {
                                                  // localStorage.setItem(
                                                  //   "shareholdervotecast",
                                                  //   JSON.stringify(
                                                  //     item.folio_number
                                                  //   )
                                                  // );
                                                  sessionStorage.setItem(
                                                    "shareholdervotecast",
                                                    JSON.stringify(
                                                      item.folio_number
                                                    )
                                                  );
                                                  item.data["logo"] =
                                                    filter?.logo;
                                                  handleVoteCast(item.data);
                                                }
                                              } else {
                                                setVotedDirectorDetail({
                                                  logo: filter?.logo,
                                                  from_date: moment(
                                                    item?.data?.election_from
                                                  ).format("DD-MMM-YYYY h:mmA"),
                                                  to_date: moment(
                                                    item?.data?.election_to
                                                  ).format("DD-MMM-YYYY h:mmA"),
                                                });

                                                setDirectorVotingView(
                                                  votedDirectorElection[voted]
                                                );
                                                setShowDirectorVotingDetail(
                                                  true
                                                );
                                              }
                                            }}
                                          >
                                            <span>
                                              {voted > -1
                                                ? "View Casted Vote"
                                                : "Cast Vote"}
                                            </span>
                                          </button>
                                        ) : (
                                          <button
                                            className="btn"
                                            type="button"
                                            style={{
                                              color: "#ffff",
                                              background: "#5B9BD5",
                                              marginTop: "-15px",
                                              width: "210px",
                                              textAlign: "center",
                                            }}
                                            disabled={true}
                                          >
                                            <span>Voting Time Expired</span>
                                          </button>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <hr style={{ width: "100%" }} />
                            </>
                          );
                        })}
                    </div>
                  )}
              </div>
              {loading && <Spinner />}
              <div
                className="card-body mt-4"
                style={{ justifyContent: "center", display: "flex" }}
              >
                {uniqueSpecialAgenda &&
                  uniqueSpecialAgenda.length > 0 &&
                  parseFloat(uniqueSpecialAgenda[0].holding || "0") > 0 && (
                    <div>
                      {uniqueSpecialAgenda &&
                        uniqueSpecialAgenda.length > 0 &&
                        uniqueSpecialAgenda?.map((item, key) => {
                          let startDate;
                          let endDate;
                          let filter;

                          let index = votedResolutionElection.findIndex(
                            (x) =>
                              x.agenda_id == item.data.item_id &&
                              x.folio_number == item.folio_number
                          );

                          startDate = new Date(item?.data?.agenda_from);
                          endDate = new Date(item?.data?.agenda_to);
                          filter = allCompanies.find((code) => {
                            return code.code === item.data?.company_code;
                          });
                          // }
                          {
                            /* else {
                            index = specialAgenda.findIndex(
                              (x) =>
                                x.meeting_id == item.meeting_id &&
                                x.company_code == item.company_code
                            );
                            startDate = new Date(specialAgenda[index]?.agenda_from);
                            endDate = new Date(specialAgenda[index]?.agenda_to);
                            filter = allCompanies.find((code) => {
                              return (
                                code.code === specialAgenda[index]?.company_code
                              );
                            });
                          } */
                          }

                          return (
                            <>
                              <div key={key}>
                                <div class="col-md-12">
                                  <div
                                    style={{
                                      alignItems: "center",
                                    }}
                                  >
                                    <div
                                      style={{
                                        alignItems: "center",
                                        justifyContent: "center",
                                        textAlign: "center",
                                      }}
                                    >
                                      <div
                                      //style={{ width: "auto", maxWidth: "120px" }}
                                      >
                                        <img
                                          src={
                                            filter.logo
                                              ? filter.logo
                                              : require("../../../assets/images/defaultImg.png")
                                          }
                                          class="card-img"
                                          alt="Logo"
                                          style={{
                                            width: "auto",
                                            height: "auto",
                                            maxWidth: "220px",
                                            maxHeight: "120px",
                                          }}
                                        />
                                      </div>
                                      <br />
                                      <p
                                        style={{
                                          marginTop: "-20px",

                                          marginBottom: "10px",
                                        }}
                                      >
                                        {filter?.company_name}
                                      </p>
                                    </div>

                                    <div style={{ textAlign: "center" }}>
                                      <h5
                                        class="card-title"
                                        style={{ wordWrap: "break-word" }}
                                      >
                                        Special Resolution
                                      </h5>
                                      {/* <p
                                    style={{
                                      marginTop: "-5px",
                                      paddingRight: "10px",
                                      maxWidth: "335px",
                                    }}
                                    class="card-text"
                                  >
                                    {filter?.company_name}
                                  </p> */}

                                      <p
                                        style={{
                                          marginTop: "-5px",
                                          paddingRight: "10px",
                                          maxWidth: "335px",
                                        }}
                                        class="card-text"
                                      >
                                        {moment(item?.data?.agenda_from).format(
                                          "DD-MMM-YYYY h:mmA"
                                        )}
                                        <br />
                                        TO
                                        <br />
                                        {moment(item?.data?.agenda_to).format(
                                          "DD-MMM-YYYY h:mmA"
                                        )}
                                      </p>
                                      <div>
                                        <div
                                          className="d-flex "
                                          style={{
                                            marginTop: "-5px",
                                            justifyContent: "space-between",
                                            width: "100%",
                                          }}
                                        >
                                          <p
                                            style={{ marginTop: "-5px" }}
                                            class="card-text col-md-6 text-nowrap"
                                          >
                                            Shares:{" "}
                                            <b>
                                              {numberWithCommas(item?.holding)}
                                            </b>
                                          </p>
                                          <p
                                            style={{ marginTop: "-5px" }}
                                            class="card-text col-md-6 text-nowrap"
                                          >
                                            Folio #:{" "}
                                            <b>
                                              {item?.folio_number
                                                .split("-")
                                                .slice(1)
                                                .join("-")}
                                            </b>
                                          </p>
                                        </div>

                                        {new Date(currentDate).getTime() <
                                        new Date(startDate).getTime() ? (
                                          <button
                                            className="btn"
                                            type="button"
                                            style={{
                                              color: "#ffff",
                                              background: "#5B9BD5",
                                              marginTop: "-15px",
                                              minWidth: "170px",
                                              maxWidth: "170px",
                                              textAlign: "center",
                                            }}
                                            disabled={true}
                                          >
                                            <span> Yet Not Started</span>
                                          </button>
                                        ) : new Date(currentDate).getTime() >=
                                            new Date(startDate).getTime() &&
                                          new Date(currentDate).getTime() <=
                                            new Date(endDate).getTime() ? (
                                          <button
                                            className="btn"
                                            type="button"
                                            style={{
                                              color: "#ffff",
                                              background: "#5B9BD5",
                                              marginTop: "-15px",
                                              width: "190px",
                                              textAlign: "center",
                                            }}
                                            onClick={() => {
                                              if (index < 0) {
                                                if (
                                                  new Date().getTime() >=
                                                  new Date(endDate).getTime()
                                                ) {
                                                  toast.error(
                                                    "Voting Time Expired"
                                                  );
                                                  setTimeout(() => {
                                                    window.location.reload();
                                                  }, 3000);
                                                } else {
                                                  // localStorage.setItem(
                                                  //   "shareholdervotecast",
                                                  //   JSON.stringify(
                                                  //     item.folio_number
                                                  //   )
                                                  // );
                                                  sessionStorage.setItem(
                                                    "shareholdervotecast",
                                                    JSON.stringify(
                                                      item.folio_number
                                                    )
                                                  );
                                                  item["logo"] = filter?.logo;
                                                  handleSpecialResolutionVoteCast(
                                                    item
                                                  );
                                                }
                                              } else {
                                                setVotedResolutionDetail({
                                                  logo: filter?.logo,
                                                  from_date: moment(
                                                    item?.data?.agenda_from
                                                  ).format("DD-MMM-YYYY h:mmA"),
                                                  to_date: moment(
                                                    item?.data?.agenda_to
                                                  ).format("DD-MMM-YYYY h:mmA"),
                                                });

                                                setResolutioVotingView(
                                                  votedResolutionElection[index]
                                                );
                                                setSpecialVotingDetail(true);
                                              }
                                            }}
                                          >
                                            {index < 0
                                              ? " Cast Vote "
                                              : "View Casted Vote"}
                                          </button>
                                        ) : (
                                          <button
                                            className="btn"
                                            type="button"
                                            style={{
                                              color: "#ffff",
                                              background: "#5B9BD5",
                                              marginTop: "-15px",
                                              width: "210px",
                                              textAlign: "center",
                                            }}
                                            disabled={true}
                                          >
                                            <span>Voting Time Expired</span>
                                          </button>
                                        )}
                                      </div>
                                      {item?.title?.includes(
                                        "Special Resolution"
                                      ) && (
                                        <button
                                          className="btn"
                                          type="button"
                                          style={{
                                            color: "#ffff",
                                            background: "#5B9BD5",
                                            marginTop: "-15px",
                                          }}
                                          // disabled={true}
                                          onClick={() => {
                                            handleViewResolution(item);
                                          }}
                                        >
                                          <span> View Resolution</span>
                                        </button>
                                      )}
                                      {item?.waitingResult && (
                                        <button
                                          className="btn"
                                          type="button"
                                          style={{
                                            color: "#ffff",
                                            background: "#5B9BD5",
                                            marginTop: "-15px",
                                          }}
                                          disabled={true}
                                        >
                                          <span> Result</span>
                                        </button>
                                      )}
                                      {item?.specialvoting && (
                                        <button
                                          className="btn"
                                          type="button"
                                          style={{
                                            color: "#ffff",
                                            background: "#5B9BD5",
                                            marginTop: "-15px",
                                            minWidth: "170px",
                                            maxWidth: "170px",
                                          }}
                                          onClick={() => {
                                            viewSpecialVotingResult(item);
                                          }}
                                        >
                                          <span> Special Result</span>
                                        </button>
                                      )}
                                      {item?.electionvoting && (
                                        <button
                                          className="btn"
                                          type="button"
                                          style={{
                                            color: "#ffff",
                                            background: "#5B9BD5",
                                            marginTop: "-15px",
                                            minWidth: "170px",
                                            maxWidth: "170px",
                                          }}
                                          onClick={() => {
                                            viewElectionResult(item);
                                          }}
                                        >
                                          <span> Election Result</span>
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <hr style={{ width: "100%" }} />
                            </>
                          );
                        })}
                    </div>
                  )}
              </div>
              {!loading &&
                electionofDirectors.length == 0 &&
                uniqueSpecialAgenda.length == 0 && (
                  <div style={{ textAlign: "center" }}>
                    There are currently no ongoing elections.{" "}
                  </div>
                )}
            </div>
          </ResponsiveWrapper>
        </MainWrapper>
      </div>
    </>
  );
};

const MainWrapper = styled.div`
  @media (min-width: 300px) {
    .responsivepage {
    }
  }
  @media (min-width: 500px) {
    .responsivepage {
      display: flex;
      justify-content: center;
      width: 70%;
    }
  }
`;
const ResponsiveWrapper = styled.div`
  @media (min-width: 300px) {
    .responsivhomePage {
    }
  }
  @media (min-width: 500px) {
    .responsivhomePage {
      min-width: 550px;
      max-width: 550px;
    }
  }
`;
const Wrapper = styled.div`
  @media @media (min-width: 300px) {
    .responsive {
      width: 80%;
    }
  }
  @media (min-width: 500px) {
    .responsive {
      width: 53%;
    }
  }
  @media (min-width: 660px) {
    .responsive {
      width: 45%;
    }
  }
  @media (min-width: 840px) {
    .responsive {
      width: 35%;
    }
  }
  @media (min-width: 1132px) {
    .responsive {
      width: 26%;
    }
  }

  @media (min-width: 1132px) {
    .responsive {
      width: 22%;
    }
  }

  @media (min-width: 1300px) {
    .responsive {
      width: 20%;
    }
  }
  @media (min-width: 1470px) {
    .responsive {
      width: 15%;
    }
  }
`;
const PaddingWrapper = styled.div`
  @media (min-width: 300px) {
    .responsivepaddingpage {
      ${"" /* width: 100%; */}
    }
    .imageStyle {
      max-width: 80px;
      max-height: 80px;
    }
  }
  @media (min-width: 500px) {
    .responsivepaddingpage {
      ${
        "" /* display: flex;
      justify-content: center; */
      }
      padding: 0px 100px;
      ${
        "" /* width: 50%;
      margin:auto; */
      }
    }
  }
`;
const ModelPaddingWrapper = styled.div`
  @media (min-width: 300px) {
    .responsivemodelpaddingpage {
      ${"" /* width: 100%; */}
    }
  }
  @media (min-width: 500px) {
    .responsivemodelpaddingpage {
      ${
        "" /* display: flex;
      justify-content: center; */
      }
      padding: 0px 20px;
      ${
        "" /* width: 50%;
      margin:auto; */
      }
    }
  }
`;
