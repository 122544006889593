import React, { useState } from 'react'
import { AiOutlineLeft } from "react-icons/ai";
import styled from 'styled-components';
import { useHistory } from 'react-router';
import DCCLogo from "../../../assets/DCC-Logo.svg"
import { FaRegFile } from "react-icons/fa";
import "./evotingStyle.scss";
import { Logout } from './logout';

export const SpecialVoting = () => {
    // const getEvotingData = JSON.parse(localStorage.getItem('evotingData'))
    const getEvotingData = JSON.parse(sessionStorage.getItem('evotingData'))
    const [termCondition, setTermCondition] = useState(false)
    const [otp, setOpt] = useState('')
    const history = useHistory()
    const handleCastVoting = () => {
        window.location.replace(
            `${process.env.PUBLIC_URL}/evoting/home`
        );
    }
    return (
        <div  className="evotingfontfamily"    style={{ padding: '20px' }}>
            <MainWrapper className='row mt-4'>
                <div className="col-sm-12 col-md-12 col-lg-12 responsivepage" >
                    <div className='card pb-5' style={{ border: '1px solid #70ace7' }}>
                        <Wrapper className="card-header " style={{ borderBottom: 'none' }}>
                        <div className='d-flex' style={{justifyContent:'space-between'}}>
            <AiOutlineLeft className='mt-3' size={30} style={{ color: '#677ecc', cursor: 'pointer' }} 
              onClick={() => {
                                history.push(`${process.env.PUBLIC_URL}/special-resolution`)

                                //   setReviewVoting(false)
                            }}/>
              <Logout />
            </div>
                            
                            <div className="glyphicon glyphicon-chevron-left"></div>
                            <div className="text-center mt-1">
                                <img src={DCCLogo} alt="" width="220" height={'130px'} />
                            </div>
                            <div className='mt-3' style={{ justifyContent: 'center', display: 'flex' }}>
                                eVoting On
                            </div>
                            <div className='mt-3' style={{ color: '#447bc9', fontSize: '23px', display: 'flex', justifyContent: 'center' }}>
                                {getEvotingData?.title || ''}
                            </div>
                            <p style={{ fontSize: '16px', display: 'flex', justifyContent: 'center', color: 'rgb(152, 161, 181)' }} class="card-text">{getEvotingData?.startdate} to {getEvotingData?.endDate}</p>


                        </Wrapper>
                        <div className="card-body " style={{ justifyContent: 'center', display: 'flex' }}>
                            <div className='mt-5'>


                                <div style={{ display: 'flex', gap: '30px', justifyContent: 'center', paddingLeft: '15px' }}>
                                    <input type="checkbox" id="checkbox" name="checkbox" style={{ transform: 'scale(2)' }} onChange={(e) => {
                                        setTermCondition(e?.target?.checked)
                                       
                                    }} />
                                    <div style={{ fontSize: '18px' }}>Accept</div>
                                </div>
                                <div className='mt-4' style={{ display: 'flex', gap: '30px', justifyContent: 'center', paddingLeft: '15px' }}>
                                    <input type="checkbox" id="checkbox" name="checkbox" style={{ transform: 'scale(2)' }} onChange={(e) => {
                                        setTermCondition(e?.target?.checked)
                                       
                                    }} />
                                    <div style={{ fontSize: '18px' }}>Reject</div>

                                </div>
                                <div className='mt-5'>
                                    <div className='mt-5'>
                                        <label className="col-form-label pt-0 justify-content-center d-flex mt-5" style={{ fontSize: '16px', color: 'rgb(152, 161, 181)' }}>
                                            Enter Confirmation OTP
                                        </label>
                                        <input style={{ minWidth: '260px', maxWidth: '260px', textAlign: 'center' }} value={otp} type='text' className="form-control mt-1"
                                            onChange={(e) => setOpt(e?.target?.value)} />
                                    </div>
                                </div>









                                <button
                                    className="btn mt-4"
                                    type="button"
                                    style={{ width: '100%', color: '#ffff', background: '#5B9BD5' }}
                                    onClick={() => {
                                        handleCastVoting()
                                    }}
                                >


                                    <span> Submit</span>

                                </button>
                            </div>









                        </div>

                    </div>
                </div>
            </MainWrapper>



        </div>
    )
}
const MainWrapper = styled.div`
@media(min-width: 300px){
    .responsivepage{

    }
  }
  @media(min-width: 500px){
    .responsivepage{
display: flex;
justify-content: center
}
  }
`;
const Wrapper = styled.div`
@media(min-width: 300px){
    .responsive{
    width: 80%;
    }
  }
  @media(min-width: 500px){
    .responsive{
    width: 53%;
    }
  }
  @media(min-width: 660px){
    .responsive{
    width: 45%;
    }
  }
  @media(min-width: 840px){
    .responsive{
    width : 35%;
    }
  }
  @media(min-width: 1132px){
    .responsive{
    width : 26%;
    }
  }
  
  @media(min-width: 1132px){
    .responsive{
    width : 22%;
    }
  }


@media(min-width: 1300px){
    .responsive{
    width : 20%;
    }
  }
  @media(min-width: 1470px){
    .responsive{
    width : 15%;
    }
  }
`;