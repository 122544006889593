import React, { useState, useEffect } from "react";
import logo from "../assets/images/shareholder-app.svg";
import macslogo from "../assets/images/macs-core-logo.png";
import man from "../assets/images/dashboard/user.png";
import logos from "../assets/share-registrar.svg";
import DCCLogo from "../assets/DCC-Logo.svg";
import { ToastContainer, toast } from "react-toastify";
import NumberFormat from "react-number-format";
import "react-toastify/dist/ReactToastify.css";
import { withRouter } from "react-router";
import { AiOutlineLeft } from "react-icons/ai";
import {
  firebase_app,
  googleProvider,
  facebookProvider,
  twitterProvider,
  githubProvider,
  Jwt_token,
} from "../data/config";
import { handleResponse } from "../services/fack.backend";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Login,
  LOGIN,
  YourName,
  Password,
  RememberMe,
  LoginWithAuth0,
  LoginWithJWT,
  BackgroundColor,
} from "../constant";
import { loginService } from "../store/services/auth.service";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { investorLogin, verifyLogin } from "../store/services/evoting.service";
import InputMask from "react-input-mask";
//const config = require("../data/app_config.json");
import Config from "../config/index";

const Signin = ({ history, location }) => {
  // const { loginWithRedirect } = useAuth0();
  const [cnic, setCnic] = useState("");
  const [otp, setOTP] = useState("");
  // const [password, setPassword] = useState("");
  const [Loading, setLoading] = useState(false);
  const [cnicError, setCnicError] = useState(false);
  const [evotinLoading, setEvotingLoading] = useState(false);
  const [otpLoading, setotpLoading] = useState(false);
  const [otpError, setOTPError] = useState(false);
  const [EvotingMethod, setEvotingMethd] = useState(true);
  //   const [value, setValue] = useState(localStorage.getItem("profileURL" || man));
  const [otpMethod, setOtpMethod] = useState(false);
  const [userData, setUserData] = useState({});
  const [placeholder, setPlaceholder] = useState("cnic");
  const [maskType, setMaskType] = useState("cnic");
  const [selectedIdentification, setSelectedIdentification] = useState("cnic");
  const [inputValue, setInputValue] = useState("");

  const handleRadioChange = (e) => {
    setCnicError(false);
    setSelectedIdentification(e.target.value);
    setMaskType(e.target.value);
    setInputValue("");
  };
  const getMaskingType = () => {
    if (maskType === "cnic") {
      return "99999-9999999-9";
    } else if (maskType === "ntn") {
      return "999999999";
    } else if (maskType === "passport") {
      return "***********";
    }
    return;
  };
  const getPlaceholder = (e) => {
    if (selectedIdentification === "cnic") {
      return "Enter Your CNIC";
    } else if (selectedIdentification === "ntn") {
      return "Enter Your NTN";
    } else if (selectedIdentification === "passport") {
      return "Enter Your Passport#";
    }

    return;
  };

  // errors
  // const [emailError, setemailError] = useState(false);
  // const [passwordError, setpasswordError] = useState(false);

  // useEffect(() => {
  //   console.log(location);
  //   if (value !== null) localStorage.setItem("profileURL", value);
  //   else localStorage.setItem("profileURL", man);
  // }, [value]);

  // const loginAuth = async () => {
  //   if (cnic == "") {
  //     setemailError(true);
  //   } else {
  //     setemailError(false);
  //   }
  //   if (password == "") {
  //     setpasswordError(true);
  //   } else {
  //     setpasswordError(false);
  //   }
  //   if (cnic !== "" && password !== "") {
  //     try {
  //       setLoading(true);
  //       const response = await loginService(cnic, password);
  //       if (response.status === 200) {
  //         toast.success(response.data.message);
  //         sessionStorage.setItem("email", response.data.email);
  //         sessionStorage.setItem("name", response.data.data.name);
  //         sessionStorage.setItem("role", response.data.data.role);
  //         // sessionStorage.setItem("features", response.data.data.features);
  //         sessionStorage.setItem("cnic", response.data.data.cnic);
  //         sessionStorage.setItem("mobile", response.data.data.mobile);
  //         sessionStorage.setItem("token", response.data.data.accessToken);
  //         setTimeout(() => {
  //           history.push(`${process.env.PUBLIC_URL}/dashboard/default`);
  //         }, 1000);
  //       } else {
  //         toast.error(
  //           "Oppss.. The password is invalid or the user does not have a password."
  //         );
  //       }
  //       setLoading(false);
  //     } catch (error) {
  //       setTimeout(() => {
  //         toast.error(
  //           "Oppss.. The password is invalid or the user does not have a password."
  //         );
  //       }, 200);
  //       setLoading(false);
  //     }
  //   }
  // };

  // const loginWithJwt = (email, password) => {
  //   const requestOptions = {
  //     method: "POST",
  //     headers: { "Content-Type": "application/json" },
  //     body: { email, password },
  //   };

  //   return fetch("/users/authenticate", requestOptions)
  //     .then(handleResponse)
  //     .then((user) => {
  //       // store user details and jwt token in local storage to keep user logged in between page refreshes
  //       setValue(man);
  //       localStorage.setItem("token", user);
  //       window.location.href = `${process.env.PUBLIC_URL}/dashboard/default`;
  //       return user;
  //     });
  // };

  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const handleCheckboxChange = (event) => {
    setIsCheckboxChecked(event.target.checked);
  };

  const EvotingmethodCheck = async () => {
    if (!cnic) {
      setCnicError(true);
      setInputValue("");
      return;
    } else {
      setCnicError(false);
      setEvotingLoading(true);
      setOTP("");
      const trimmedCnic = inputValue.replaceAll("_", "").replaceAll("-", "");
      if (maskType === "cnic" && trimmedCnic.length != 13) {
        toast.error("CNIC should be 15 digits");
        setInputValue("");
        setEvotingLoading(false);
        return;
      }
      if (
        (maskType === "ntn" && trimmedCnic.length < 7) ||
        (maskType === "ntn" && trimmedCnic.length > 9)
      ) {
        toast.error("NTN should be between 7 to 9 digits");
        setInputValue("");
        setEvotingLoading(false);
        return;
      }
      if (
        (maskType === "passport" && trimmedCnic.length < 9) ||
        (maskType === "ntn" && trimmedCnic.length > 11)
      ) {
        toast.error("Passport # should be between 9 to 11 digits");
        setInputValue("");
        setEvotingLoading(false);
        return;
      }
      try {
        const response = await investorLogin(trimmedCnic.trim(), maskType);
        if (response.data.status == 200) {
          toast.success(response.data.message);
          setUserData({
            email: response.data.email,
            mobile_no: response.data.mobile,
            cnic: trimmedCnic,
            type: maskType,
          });
          // localStorage.setItem(
          //   "user_data",
          //   JSON.stringify({
          //     email: response.data.email,
          //     mobile_no: response.data.mobile,
          //     cnic: trimmedCnic,
          //     type: maskType,
          //   })
          // );
          sessionStorage.setItem(
            "user_data",
            JSON.stringify({
              email: response.data.email,
              mobile_no: response.data.mobile,
              cnic: trimmedCnic,
              type: maskType,
            })
          );

          setEvotingLoading(false);
          setEvotingMethd(false);
          setOtpMethod(true);
        } else {
          setEvotingLoading(false);
          toast.error(response.data.message);
        }
      } catch (error) {
        console.log(error.message);
        toast.error(
          "Network Error. Check your internet connection and try again!"
        );
        setEvotingLoading(false);
      }
    }
  };
  const LoginMEthod = async () => {
    if (!otp) {
      setOTPError(true);
      return;
    } else {
      setotpLoading(true);
      setOTPError(false);
      try {
        const response = await verifyLogin(
          userData.email,
          otp.trim(),
          userData.mobile_no
        );
        if (response.data.status == 200) {
          // localStorage.setItem("email", JSON.stringify(response.data.email));
          sessionStorage.setItem("email", JSON.stringify(response.data.email));
          // localStorage.setItem("token", JSON.stringify(response.data.token));
          sessionStorage.setItem("token", JSON.stringify(response.data.token));
          // localStorage.setItem(
          //   "user_company",
          //   JSON.stringify(response.data.company_codes)
          // );
          sessionStorage.setItem(
            "user_company",
            JSON.stringify(response.data.company_codes)
          );
          // localStorage.setItem(
          //   "user_last_shareholder",
          //   JSON.stringify(response.data.sharehodlerDetails)
          // );
          // sessionStorage.setItem(
          //   "user_last_shareholder",
          //   JSON.stringify(response.data.sharehodlerDetails)
          // );
          // sessionStorage.setItem("email", JSON.stringify(response.data.email));
          // sessionStorage.setItem("token", JSON.stringify(response.data.token));
          // localStorage.setItem("login_date", response.data.date);
          sessionStorage.setItem("login_date", response.data.date);
          setotpLoading(false);
          setOtpMethod(false);
          setEvotingMethd(false);
          window.location.replace(`${process.env.PUBLIC_URL}/evoting/home`);
        } else {
          toast.error(response.data.message);
          setotpLoading(false);
        }
      } catch (error) {
        console.log(error.message);
        toast.error(
          "Network Error. Check your internet connection and try again!"
        );
        setotpLoading(false);
      }
    }

    // history.push(`${process.env.PUBLIC_URL}/dashboard/default`);
  };

  return (
    <div>
      <ToastContainer />
      <div className="page-wrapper">
        {/* <div className="container-fluid p-0"> */}
        {/* <!-- login page start--> */}
        <div className="authentication-main" style={{ padding: "5px 15px" }}>
          {/* <div className="row"> */}

          <div>
            <div className="auth-innerright">
              <div className="authentication-box">
                {/* <div className="text-center">
                      <img src={macslogo} width="130" alt="" />
                      <div className="text-center"></div>
                    </div> */}

                <div
                  className="card mt-4"
                  style={{ width: "100%", border: "1px solid #5B9BD5" }}
                >
                  {otpMethod && (
                    <AiOutlineLeft
                      className="mt-3 ml-3"
                      size={30}
                      style={{ color: "#677ecc", cursor: "pointer" }}
                      onClick={() => {
                        setEvotingMethd(true);
                        setOtpMethod(false);
                      }}
                    />
                  )}
                  <div className="card-body">
                    <div className="text-center">
                      <img src={logos} alt="" className="py-3" width="220" />
                    </div>
                    <div className="text-center">
                      <img src={logo} alt="" className="py-3" width="80" />
                    </div>

                    {/* {EvotingMethod ? <div className="d-flex justify-content-center"> INVESSTOR</div> : ''} */}

                    {EvotingMethod ? (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          height: "100%",
                          margin: "auto",
                        }}
                      >
                        <form
                          className="theme-form pb-5"
                          style={{ width: "100%" }}
                          onSubmit={(e) => e.preventDefault()}
                        >
                          <div className="form-group">
                            <label className="col-form-label pt-0 justify-content-center d-flex mb-2">
                              {getPlaceholder()}
                            </label>

                            <div className="d-flex justify-content-center">
                              <div style={{ display: "inline-block" }}>
                                <input
                                  style={{ margin: "3px" }}
                                  type="radio"
                                  id="cnic"
                                  name="identification"
                                  value="cnic"
                                  checked={selectedIdentification === "cnic"}
                                  onChange={handleRadioChange}
                                />
                                <label htmlFor="cnic">CNIC</label>
                              </div>
                              <div
                                style={{
                                  display: "inline-block",
                                  marginLeft: "10px",
                                }}
                              >
                                <input
                                  style={{ margin: "3px" }}
                                  type="radio"
                                  id="ntn"
                                  name="identification"
                                  value="ntn"
                                  checked={selectedIdentification === "ntn"}
                                  onChange={handleRadioChange}
                                />
                                <label htmlFor="ntn">NTN</label>
                              </div>
                              <div
                                style={{
                                  display: "inline-block",
                                  marginLeft: "10px",
                                }}
                              >
                                <input
                                  style={{ margin: "3px" }}
                                  type="radio"
                                  id="passport"
                                  name="identification"
                                  value="passport"
                                  checked={
                                    selectedIdentification === "passport"
                                  }
                                  onChange={handleRadioChange}
                                />
                                <label htmlFor="passport">Passport #</label>
                              </div>
                            </div>
                            {/* <InputMask
                          className={`form-control`}
                          placeholder="Enter Your CNIC"
                          mask="99999-9999999-9"
                          onChange={(e) => setCnic(e.target.value)}
                          type="cnic"
                            name="cnic"
                            value={cnic}
                            style={{ width: '90%', margin: 'auto', border: '1px solid #70ace7', textAlign: 'center' }}
                        ></InputMask> */}
                            <div>
                              <InputMask
                                className="form-control"
                                type="text"
                                name="cnic"
                                value={inputValue}
                                mask={getMaskingType()}
                                disabled={Boolean(evotinLoading)}
                                formatChars={{
                                  9: "[0-9-]",
                                  A: "[a-zA-Z]",
                                  "*": "[a-zA-Z0-9]",
                                }}
                                onKeyPress={(e) => {
                                  let key_board_keycode = e.which || e.keyCode;
                                  if (
                                    key_board_keycode === 13 &&
                                    evotinLoading === false
                                  ) {
                                    e.preventDefault();
                                    setEvotingLoading(true);
                                    EvotingmethodCheck();
                                  }
                                }}
                                style={{
                                  width: "90%",
                                  margin: "auto",
                                  border: "1px solid #70ace7",
                                  textAlign: "center",
                                }}
                                onChange={(e) => {
                                  setCnic(e.target.value);
                                  setInputValue(e.target.value);
                                }}
                                placeholder={getPlaceholder()}
                              />
                            </div>
                            {cnicError && (
                              <p
                                className="error-color"
                                style={{ width: "90%", margin: "auto" }}
                              >
                                *{" "}
                                {maskType === "passport"
                                  ? "Passport #"
                                  : maskType.toUpperCase()}{" "}
                                is required
                              </p>
                            )}
                          </div>

                          <div className="form-group form-row mt-4 mb-0">
                            <button
                              className="btn"
                              type="button"
                              style={{
                                width: "90%",
                                margin: "auto",
                                color: "#ffff",
                                background: "#5B9BD5",
                              }}
                              onClick={() => EvotingmethodCheck()}
                              disabled={Boolean(evotinLoading)}
                            >
                              {evotinLoading ? (
                                <>
                                  <span className="fa fa-spinner fa-spin"></span>
                                  <span> Loading...</span>
                                </>
                              ) : (
                                <span> NEXT</span>
                              )}
                            </button>
                          </div>
                        </form>
                      </div>
                    ) : null}

                    {otpMethod ? (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          height: "100%",
                          margin: "auto",
                        }}
                      >
                        <form
                          className="theme-form pb-5"
                          style={{ width: "100%" }}
                          onSubmit={(e) => e.preventDefault()}
                        >
                          <div className="form-group">
                            <label className="col-form-label pt-0 justify-content-center d-flex mb-">
                              Enter OTP Authentication
                            </label>
                            {/* <NumberFormat
                              className="form-control"
                             
                              placeholder="Enter OTP"
                              
                              onKeyPress={(e) => {
                                let key_board_keycode = e.which || e.keyCode;
                                if (key_board_keycode == 13) {
                                  e.preventDefault();
                          
                                  LoginMEthod()
                                  
                                }
                              }}
                              style={{ width: '90%', margin: 'auto', border: '1px solid #70ace7', textAlign: 'center' }}

                              decimalScale={0}
                              allowNegative={false} // Disallow negative numbers
                              allowLeadingZeros={false} // Disallow leading zeros
                            /> */}

                            <input
                              className="form-control"
                              value={otp ? otp : " "}
                              type="number"
                              onKeyPress={(e) => {
                                let key_board_keycode = e.which || e.keyCode;
                                if (
                                  key_board_keycode == 13 &&
                                  isCheckboxChecked
                                ) {
                                  e.preventDefault();

                                  LoginMEthod();
                                }
                              }}
                              style={{
                                width: "90%",
                                margin: "auto",
                                border: "1px solid #70ace7",
                                textAlign: "center",
                              }}
                              onChange={(e) => {
                                let text = e.target.value;
                                if (
                                  text.length == 0 ||
                                  (Number(text) >= 0 &&
                                    isNaN(text) == false &&
                                    Number.isInteger(Number(text)))
                                ) {
                                  setOTP(text?.replaceAll("-", ""));
                                }
                              }}
                              placeholder="Enter OTP"
                            />
                            {cnicError && (
                              <p
                                className="error-color"
                                style={{ width: "90%", margin: "auto" }}
                              >
                                * OTP is required
                              </p>
                            )}
                          </div>

                          <div className="form-group form-row mt-3 mb-0">
                            <div className="container">
                              <div class="form-check m-3">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={isCheckboxChecked}
                                  onChange={handleCheckboxChange}
                                  id="flexCheckDefault"
                                  style={{ cursor: "pointer" }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexCheckDefault"
                                  style={{ cursor: "pointer" }}
                                >
                                  I agree to the{" "}
                                  <a
                                    href="#"
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    Terms and Conditions
                                  </a>{" "}
                                  of the eVoting
                                </label>
                              </div>
                            </div>

                            <button
                              className="btn"
                              type="button"
                              style={{
                                width: "90%",
                                margin: "auto",
                                color: "#fff",
                                background: "#5B9BD5",
                              }}
                              onClick={LoginMEthod}
                              disabled={!isCheckboxChecked}
                            >
                              LOGIN
                            </button>
                          </div>
                        </form>
                      </div>
                    ) : null}
                  </div>
                  <div className="card-footer">
                    <div className="text-center opacity-50 mt-3">
                      <span>Powered By</span>
                    </div>
                    <div className="text-center">
                      <img src={DCCLogo} width="100" alt="" />
                      <div className="text-center"></div>
                    </div>

                    <hr style={{ width: "60%" }} />
                    <div
                      className="text-center opacity-50"
                      style={{ color: "grey" }}
                    >
                      <h6>
                        {Config.appTitle} {Config.appVersion}
                      </h6>
                    </div>
                  </div>
                </div>

                {/* <div className="card mt-4">
                      <div className="card-body">
                        <div className="text-center">
                          <img src={logo} alt="" className="py-3" width="250" />
                        </div>
                        <form className="theme-form">
                          <div className="form-group">
                            <label className="col-form-label pt-0">
                              Enter CNIC/NTN
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name="cnic"
                              defaultValue={location?.state?.cnic}
                              value={cnic}
                              onChange={(e) => setCnic(e.target.value)}
                              placeholder="Enter CNIC/NTN"
                            />
                            {emailError && (
                              <p className="error-color">
                                * CNIC/NTN is required
                              </p>
                            )}
                          </div>
                          <div className="form-group">
                            <label className="col-form-label">{Password}</label>
                            <input
                              className="form-control"
                              type="password"
                              name="password"
                              placeholder="Password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                            {passwordError && (
                              <p className="error-color">
                                * Password is required
                              </p>
                            )}
                          </div>
                          <div className="checkbox p-0">
                            <input id="checkbox1" type="checkbox" />
                            <label htmlFor="checkbox1">{RememberMe}</label>
                          </div>
                          <div className="form-group form-row mt-3 mb-0">
                           
                            <NavLink to="/signup">
                              Activate Your Account Now!
                            </NavLink>
                            <button
                              className="btn btn-primary btn-block"
                              type="button"
                              onClick={() => loginAuth()}
                              disabled={Boolean(Loading)}
                            >
                              {Loading ? (
                                <>
                                  <span className="fa fa-spinner fa-spin"></span>
                                  <span> Loading...</span>
                                </>
                              ) : (
                                <span> {Login}</span>
                              )}
                            </button>
                          </div>

                          <hr style={{ width: "60%" }} />
                        
                        </form>
                      </div>
                    </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
    // </div>
    // </div>
  );
};

export default withRouter(Signin);

const Wrapper = styled.div``;
