import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { LogOut } from "react-feather";
import { useHistory } from 'react-router-dom';

export const Logout = () => {
  const [flag, setFlag] = useState(false);
  const history = useHistory();
  return (
    <>
      <Modal isOpen={flag} show={flag.toString()} size="xs">
        <ModalHeader
          toggle={() => {
            setFlag(false);
          }}
        >
          Logout
        </ModalHeader>
        <ModalBody>
          <div className="evotingfontfamily">
            <div className="row mt-4 ">
              <ModelPaddingWrapper className="col-sm-12 col-md-12 col-lg-12 responsivepage">
                <div
                  className="card pb-5 "
                  style={{ border: "1px solid #70ace7" }}
                >
                  <div className="responsivemodelpaddingpage">
                    <div className="text-center mt-3">
                      Do you want to Logout?
                    </div>
                    <div className="d-flex mt-5" style={{justifyContent:'space-between'}}>
                     

                      <button
                        className="btn"
                        type="button"
                        style={{
                          color: "#ffff",
                          background: "#5B9BD5",
                          marginTop: "-15px",
                          width:"40%",
                          marginLeft:"5px"
                        }}
                        onClick={()=>setFlag(false)}
                      >
                        Cancel{" "}
                      </button>
                      <button
                        className="btn"
                        type="button"
                        style={{
                          color: "#ffff",
                          background: "#5B9BD5",
                          marginTop: "-15px",
                          width:"40%",
                          marginRight:"5px"
                        }}
                        onClick={()=>{
                            localStorage.clear()
                            sessionStorage.clear()
                            history.replace(`${process.env.PUBLIC_URL}/login`)
                            

                        }}
                      >
                        
                        Logout
                      </button>
                    </div>
                  </div>
                </div>
              </ModelPaddingWrapper>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <div
        className="d-flex mt-3 mr-2"
        style={{ justifyContent: "right", textAlign: "center" }}
        onClick={() => setFlag(true)}
      >
        <LogOut /> <span className="ml-2"> Log out </span>
      </div>
    </>
  );
};
const ModelPaddingWrapper = styled.div`
  @media (min-width: 300px) {
    .responsivemodelpaddingpage {
      ${"" /* width: 100%; */}
    }
  }
  @media (min-width: 500px) {
    .responsivemodelpaddingpage {
      ${
        "" /* display: flex;
      justify-content: center; */
      }
      padding: 0px 20px;
      ${
        "" /* width: 50%;
      margin:auto; */
      }
    }
  }
`;
const Wrapper = styled.div`
  @media @media (min-width: 300px) {
    .responsive {
      width: 80%;
    }
  }
  @media (min-width: 500px) {
    .responsive {
      width: 53%;
    }
  }
  @media (min-width: 660px) {
    .responsive {
      width: 45%;
    }
  }
  @media (min-width: 840px) {
    .responsive {
      width: 35%;
    }
  }
  @media (min-width: 1132px) {
    .responsive {
      width: 26%;
    }
  }

  @media (min-width: 1132px) {
    .responsive {
      width: 22%;
    }
  }

  @media (min-width: 1300px) {
    .responsive {
      width: 20%;
    }
  }
  @media (min-width: 1470px) {
    .responsive {
      width: 15%;
    }
  }
`;
