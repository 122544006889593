import axios from "axios";
import Config from "../../config";
import RefreshTokenHandler from "./refresh-token";

export const getAllElectionOfDirector = async (email) => {
  // const token = JSON.parse(localStorage.getItem("token") || "");
  const token = JSON.parse(sessionStorage.getItem("token") || "");
  try {
    const url = `${Config.baseUrl}/election?email=${email}`;
    const result = await axios.get(url, {
      headers: {
        Authorization: token,
      },
    });
    return result;
  } catch (err) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await getAllElectionOfDirector(email);
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};

export const getAllSpecialAgenda = async (email) => {
  // const token = JSON.parse(localStorage.getItem("token") || "");
  const token = JSON.parse(sessionStorage.getItem("token") || "");
  try {
    const url = `${Config.baseUrl}/election/special-agenda?email=${email}`;
    const result = await axios.get(url, {
      headers: {
        Authorization: token,
      },
    });
    return result;
  } catch (err) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await getAllSpecialAgenda(email);
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};

export const getCandidateDatabyElectionID = async (
  email,
  election_id,
  company_code
) => {
  // const token = JSON.parse(localStorage.getItem("token") || "");
  const token = JSON.parse(sessionStorage.getItem("token") || "");
  try {
    const url = `${Config.baseUrl}/election/candidate/by-election-id?email=${email}&election_id=${election_id}&company_code=${company_code}`;
    const result = await axios.get(url, {
      headers: {
        Authorization: token,
      },
    });
    return result;
  } catch (err) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await getCandidateDatabyElectionID(
          email,
          election_id,
          company_code
        );
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};
export const getSharesByCNIC = async (email, company_code, cnic) => {
  // const token = JSON.parse(localStorage.getItem("token") || "");
  const token = JSON.parse(sessionStorage.getItem("token") || "");
  try {
    const url = `${Config.baseUrl}/shareholders/by-cnic?email=${email}&company_code=${company_code}&cnic=${cnic}`;
    const result = await axios.get(url, {
      headers: {
        Authorization: token,
      },
    });
    return result;
  } catch (err) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await getSharesByCNIC(email, company_code, cnic);
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};

export const generateOTPByMobileAndEmail = async (email, mobile_no) => {
  // const token = JSON.parse(localStorage.getItem("token") || "");
  const token = JSON.parse(sessionStorage.getItem("token") || "");
  try {
    const url = `${Config.baseUrl}/shareholders/generate-otp`;
    const result = await axios.post(
      url,
      { email, mobile_no },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return result;
  } catch (err) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await generateOTPByMobileAndEmail(email, mobile_no);
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};

export const verifyOTP = async (email, mobile_no, otp) => {
  // const token = JSON.parse(localStorage.getItem("token") || "");
  const token = JSON.parse(sessionStorage.getItem("token") || "");
  try {
    const url = `${Config.baseUrl}/shareholders/verify-otp`;
    const result = await axios.post(
      url,
      { email, mobile_no, otp },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return result;
  } catch (err) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await verifyOTP(email, mobile_no, otp);
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};

export const investorLogin = async (cnic, type) => {
  const url = `${Config.baseUrl}/shareholders/login`;
  try {
    const result = await axios.post(url, { cnic, type });
    return result;
  } catch (err) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await investorLogin(cnic);
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};

export const verifyLogin = async (email, otp, mobile_no = "") => {
  const url = `${Config.baseUrl}/shareholders/verify-login-otp`;
  try {
    const result = await axios.post(url, { otp, email, mobile_no });
    return result;
  } catch (err) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await verifyLogin(email, otp, mobile_no);
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};

export const addDirectorElectionVoting = async (
  email,
  voting_id,
  cast_type,
  cast_through,
  voter_id,
  folio_number,
  authroization_id,
  votable_shares,
  castable_votes,
  remark,
  vote_casting,
  election_id,
  votable_shares_female,
  votable_shares_independent,
  votable_shares_non_independent,
  castable_votes_female,
  castable_votes_independent,
  castable_votes_non_independent
) => {
  // const token = JSON.parse(localStorage.getItem("token") || "");
  const token = JSON.parse(sessionStorage.getItem("token") || "");
  try {
    const url = `${Config.baseUrl}/vote`;
    const result = await axios.post(
      url,
      {
        email,
        voting_id,
        cast_type,
        cast_through,
        voter_id,
        folio_number,
        authroization_id,
        votable_shares,
        castable_votes,
        remark,
        vote_casting,
        election_id,
        votable_shares_female,
        votable_shares_independent,
        votable_shares_non_independent,
        castable_votes_female,
        castable_votes_independent,
        castable_votes_non_independent,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return result;
  } catch (err) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await addDirectorElectionVoting(
          email,
          voting_id,
          cast_type,
          cast_through,
          voter_id,
          folio_number,
          authroization_id,
          votable_shares,
          castable_votes,
          remark,
          vote_casting,
          election_id,
          votable_shares_female,
          votable_shares_independent,
          votable_shares_non_independent,
          castable_votes_female,
          castable_votes_independent,
          castable_votes_non_independent
        );
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};
export const addSpecialElectionVoting = async (
  email,
  voting_id,
  agenda_id,
  cast_type,
  cast_through,
  voter_id,
  folio_number,
  authroization_id,
  votable_shares,
  castable_votes,
  vote,
  votes_casted,
  meeting_id,
  company_code
) => {
  // const token = JSON.parse(localStorage.getItem("token") || "");
  const token = JSON.parse(sessionStorage.getItem("token") || "");
  try {
    const url = `${Config.baseUrl}/vote/special-resolution-voting`;
    const result = await axios.post(
      url,
      {
        email,
        voting_id,
        agenda_id,
        cast_type,
        cast_through,
        voter_id,
        folio_number,
        authroization_id,
        votable_shares,
        castable_votes,
        vote,
        votes_casted,
        meeting_id,
        company_code,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return result;
  } catch (err) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await addSpecialElectionVoting(
          email,
          voting_id,
          agenda_id,
          cast_type,
          cast_through,
          voter_id,
          folio_number,
          authroization_id,
          votable_shares,
          castable_votes,
          vote,
          votes_casted,
          meeting_id,
          company_code
        );
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};

export const getCompaniesforVoting = async (email) => {
  // const token = JSON.parse(localStorage.getItem("token") || "");
  const token = JSON.parse(sessionStorage.getItem("token") || "");
  try {
    const url = `${Config.baseUrl}/companies?email=${email}`;
    const result = await axios.get(url, {
      headers: {
        Authorization: token,
      },
    });
    return result;
  } catch (err) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await getCompaniesforVoting(email);
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};

export const getCompaniesforVotingByCompanyCodes = async (email, companies) => {
  // const token = JSON.parse(localStorage.getItem("token") || "");
  const token = JSON.parse(sessionStorage.getItem("token") || "");
  // const companies = JSON.parse(sessionStorage.getItem("user_company")).join(
  //   ","
  // );
  try {
    const url = `${Config.baseUrl}/companies/by-multiple-ids?email=${email}&codes=${companies}`;
    const result = await axios.get(url, {
      headers: {
        Authorization: token,
      },
    });
    return result;
  } catch (err) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await getCompaniesforVotingByCompanyCodes(email, companies);
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};

export const checkResolutionvote = async (email, agenda_id, folio_number) => {
  // const token = JSON.parse(localStorage.getItem("token") || "");
  const token = JSON.parse(sessionStorage.getItem("token") || "");
  try {
    const url = `${Config.baseUrl}/vote/special-resolution-voting/by-agenda-id-folio?email=${email}&agenda_id=${agenda_id}&folio_number=${folio_number}`;
    const result = await axios.get(url, {
      headers: {
        Authorization: token,
      },
    });
    return result;
  } catch (err) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await checkResolutionvote(email, agenda_id, folio_number);
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};

export const ByCNICForVote = async (email, cnic) => {
  // const token = JSON.parse(localStorage.getItem("token") || "");
  const token = JSON.parse(sessionStorage.getItem("token") || "");
  try {
    const url = `${Config.baseUrl}/shareholders/by-cnic-for-vote?email=${email}&cnic=${cnic}`;
    const result = await axios.get(url, {
      headers: {
        Authorization: token,
      },
    });
    return result;
  } catch (err) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await ByCNICForVote(email, cnic);
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};

export const ByCNICForVotingDetails = async (email, cnic, company_codes) => {
  // const token = JSON.parse(localStorage.getItem("token") || "");
  const token = JSON.parse(sessionStorage.getItem("token") || "");
  try {
    const url = `${Config.baseUrl}/shareholders/voting-details?email=${email}&cnic=${cnic}&company_codes=${company_codes}`;
    const result = await axios.get(url, {
      headers: {
        Authorization: token,
      },
    });
    return result;
  } catch (err) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await ByCNICForVotingDetails(email, cnic, company_codes);
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};
